import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import Navbar from "./Navbar";
import {
  Flex,
  SimpleGrid,
  Button,
  Input,
  Box,
  Select,
  Icon,
} from "@chakra-ui/react";

import ProjectCard from "./common/project/card";
import CreateModal from "./common/project/create";
import UpdateModal from "./common/project/edit";
import ShowModal from "./common/project/view";

import {
  deleteProject,
  getProjects,
  searchProjects,
} from "../redux/actions/projectActions";
import { getUserById } from "../redux/actions/authActions";
import {
  useSearchParams,
  useParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { CloseIcon } from "@chakra-ui/icons";
import { CookieConsent } from "react-cookie-consent";

const Dashboard = (props) => {
  const [createable, setCreateable] = useState(false);
  const [updateable, setUpdateable] = useState(false);
  const [showable, setShowable] = useState(false);
  const [selected, setSelected] = useState(false);
  const [nameQuery, setNameQuery] = useState("");
  const [descriptionQuery, setDescriptionQuery] = useState("");
  const [statusQuery, setStatusQuery] = useState("");
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [limit, setLimit] = useState(2);

  const dispatch = useDispatch();

  const { search } = useLocation();
  const [myParams, setMyParams] = useSearchParams();

  const handleClick = (type, open, id) => {
    setSelected(id);
    switch (type) {
      case "show":
        setShowable(open);
        break;
      case "edit":
        setUpdateable(open);
        break;
      case "delete":
        dispatch(deleteProject(id));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // alert(userId);
    // alert(token);

    const userId = myParams.get("userId");
    const token = myParams.get("token");
    if (token) {
      localStorage.setItem("token", token);
      myParams.delete("token");
    }
    if (userId) myParams.delete("userId");
    setMyParams(myParams);

    if (userId && token) dispatch(getUserById({ userId, token }));
  }, [dispatch]);

  useEffect(() => {
    if (
      statusQuery.length >= 3 ||
      descriptionQuery.length >= 3 ||
      nameQuery.length >= 1 ||
      sortOrder !== ""
    ) {
      console.log(statusQuery.length);
      dispatch(
        searchProjects(
          nameQuery,
          descriptionQuery,
          statusQuery,
          sortOrder,
          currentPage,
          limit
        )
      );
    } else {
      dispatch(getProjects(currentPage, limit));
    }
  }, [
    dispatch,
    nameQuery,
    descriptionQuery,
    statusQuery,
    sortOrder,
    currentPage,
    limit,
  ]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div>
      <Navbar />

      <Flex
        flexDirection="column"
        width="100wh"
        minHeight="100vh"
        backgroundColor="white"
        justifyContent="top"
        alignItems="center"
      >
        <Button
          mt={5}
          mb={5}
          variant="solid"
          backgroundColor={"orange"}
          colorScheme="darkorange"
          onClick={() => setCreateable(props.isAuthenticated)}
        >
          Create Project
        </Button>

        <Box
          mt={5}
          mb={5}
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={3}
        >
          <Input
            color="darkorange"
            placeholder="Search by name"
            value={nameQuery}
            onChange={(e) => setNameQuery(e.target.value)}
            width="300px"
          />
          <Input
            color="darkorange"
            placeholder="Search by description"
            value={descriptionQuery}
            onChange={(e) => setDescriptionQuery(e.target.value)}
            width="300px"
          />
          <Box display={"flex"} flexDirection={"row"}>
            <Select
              onChange={(e) => setStatusQuery(e.target.value)}
              color="white"
              bg="darkorange"
              colorScheme="white"
              minWidth="100px"
              value={statusQuery}
            >
              <option selected hidden disabled value="">
                Search by status
              </option>
              <option
                style={{ backgroundColor: "darkorange", color: "white" }}
                color="darkorange"
                value="open"
              >
                Open
              </option>
              <option
                style={{ backgroundColor: "darkorange", color: "white" }}
                color="darkorange"
                value="inprogress"
              >
                In progress
              </option>
              <option
                style={{ backgroundColor: "darkorange", color: "white" }}
                color="darkorange"
                value="done"
              >
                Done
              </option>
            </Select>
            {statusQuery ? (
              <Button
                onClick={() => setStatusQuery("")}
                backgroundColor={"transparent"}
                _hover={{ backgroundColor: "transparent" }}
              >
                <Icon as={CloseIcon} color="darkorange" />
              </Button>
            ) : (
              ""
            )}
          </Box>
        </Box>

        <Box mb={5} display="flex" flexDirection="row" gap={3} minWidth="280px">
          <Select
            onChange={(e) => setSortField(e.target.value)}
            color="white"
            bg="darkorange"
            colorScheme="white"
          >
            <option selected hidden disabled value="">
              Sort by
            </option>
            <option
              style={{ backgroundColor: "darkorange", color: "white" }}
              color="darkorange"
              value="name"
            >
              Name
            </option>
            <option
              style={{ backgroundColor: "darkorange", color: "white" }}
              color="darkorange"
              value="description"
            >
              Description
            </option>
            <option
              style={{ backgroundColor: "darkorange", color: "white" }}
              color="darkorange"
              value="status"
            >
              Status
            </option>
          </Select>
          <Select
            onChange={(e) => setSortOrder(e.target.value)}
            color="white"
            bg="darkorange"
            colorScheme="white"
            minWidth="150px"
            value={sortOrder}
          >
            <option selected hidden disabled value="">
              Sort Order
            </option>
            <option
              style={{ backgroundColor: "darkorange", color: "white" }}
              color="darkorange"
              value="asc"
            >
              Asc
            </option>
            <option
              style={{ backgroundColor: "darkorange", color: "white" }}
              color="darkorange"
              value="desc"
            >
              Desc
            </option>
          </Select>
          <Select
            onChange={(e) => setLimit(Number(e.target.value))}
            color="white"
            bg="darkorange"
            colorScheme="white"
            minWidth="100px"
            value={limit}
          >
            <option
              style={{ backgroundColor: "darkorange", color: "white" }}
              color="darkorange"
              value={2}
            >
              2
            </option>
            <option
              style={{ backgroundColor: "darkorange", color: "white" }}
              color="darkorange"
              value={5}
            >
              5
            </option>
            <option
              style={{ backgroundColor: "darkorange", color: "white" }}
              color="darkorange"
              value={7}
            >
              7
            </option>
          </Select>
        </Box>

        <Flex
          flexDirection="row"
          backgroundColor="white"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          gap={10}
        >
          {props.projects &&
            props.projects.map((project, key) => (
              <ProjectCard
                key={key}
                project={project}
                onClick={(type, open, id) => handleClick(type, open, id)}
              />
            ))}
        </Flex>

        <Flex mt={5} mb={5} justifyContent="center" alignItems="center" gap={3}>
          <Button
            isDisabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
            colorScheme="darkorange"
          >
            Previous
          </Button>
          <Box color="darkorange">{`Page ${currentPage} of ${props.totalPages}`}</Box>
          <Button
            isDisabled={currentPage === props.totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
            colorScheme="darkorange"
          >
            Next
          </Button>
        </Flex>
      </Flex>
      <CreateModal isOpen={createable} onClose={() => setCreateable(false)} />
      <UpdateModal
        isOpen={updateable}
        onClose={() => setUpdateable(false)}
        projectId={selected}
      />
      <ShowModal
        isOpen={showable}
        onClose={() => setShowable(false)}
        projectId={selected}
      />
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
        {/* <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
      </CookieConsent>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
    projects: state.project.projects,
    totalPages: state.project.totalPages, // Assuming the total pages are stored in the state
  };
}

export default connect(mapStateToProps)(Dashboard);
