import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  Button,
  ModalFooter,
  Select,
  FormErrorMessage,
} from "@chakra-ui/react";
import { updateAdmin } from "../../../redux/actions/adminActions";
import { ROLES } from "constants/user";
import { getUserById } from "../../../redux/actions/authActions";

const UpdateAdminModal = ({ isOpen, onClose, admin, adminId }) => {
  const dispatch = useDispatch();
  const [adminData, setAdminData] = useState(admin);

  // useEffect(() => {
  //   console.log(admin?.name);
  // }, []);

  // useEffect(() => {
  //   if (adminId && isOpen) {
  //     dispatch(getUserById(adminId)).then((response) => {
  //       setAdminData(response.data);
  //     });
  //   }
  // }, [adminId, isOpen, dispatch]);

  useEffect(() => {
    setAdminData(admin);
  }, [admin]);

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
    name: Yup.string().required("Required"),
    // password: Yup.string().min(6, "Password must be at least 6 characters"),
    role: Yup.string()
      .oneOf(["user", "admin", "superadmin"], "Invalid role")
      .required("Required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: adminData?.email || "",
      name: adminData?.name || "",
      // password: "",
      role: adminData?.role || "user",
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(updateAdmin(adminData._id, values));
      onClose();
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent backgroundColor={"white"}>
        <ModalHeader color={"yellow.400"}>Update Admin</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={formik.handleSubmit}>
          <ModalBody pb={6}>
            <FormControl
              isInvalid={formik.errors.email && formik.touched.email}
            >
              <FormLabel color={"yellow.400"}>Email</FormLabel>
              <Input
                placeholder="Email"
                _placeholder={{ color: "yellow.400" }}
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
            </FormControl>

            <FormControl
              mt={4}
              isInvalid={formik.errors.name && formik.touched.name}
            >
              <FormLabel color={"yellow.400"}>Name</FormLabel>
              <Input
                placeholder="Name"
                _placeholder={{ color: "yellow.400" }}
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
            </FormControl>

            {/* <FormControl
              mt={4}
              isInvalid={formik.errors.password && formik.touched.password}
            >
              <FormLabel color={"yellow.400"}>Password</FormLabel>
              <Input
                type="password"
                placeholder="Password"
                _placeholder={{ color: "yellow.400" }}
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
            </FormControl> */}

            <FormControl
              mt={4}
              isInvalid={formik.errors.role && formik.touched.role}
            >
              <FormLabel color={"yellow.400"}>Role</FormLabel>
              <Select
                color="white"
                bg="darkorange"
                colorScheme="white"
                name="role"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.role}
              >
                <option selected hidden disabled value="">
                  Select role
                </option>
                <option
                  style={{ backgroundColor: "darkorange", color: "white" }}
                  color="darkorange"
                  value={ROLES.USER}
                >
                  User
                </option>
                <option
                  style={{ backgroundColor: "darkorange", color: "white" }}
                  color="darkorange"
                  value={ROLES.ADMIN}
                >
                  Admin
                </option>
                <option
                  style={{ backgroundColor: "darkorange", color: "white" }}
                  color="darkorange"
                  value={ROLES.SUPERADMIN}
                >
                  Superadmin
                </option>
              </Select>
              <FormErrorMessage>{formik.errors.role}</FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="yellow" borderRadius={0} mr={3} type="submit">
              Save
            </Button>
            <Button borderRadius={0} onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default UpdateAdminModal;
