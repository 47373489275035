import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  Button,
  ModalFooter,
  Select,
  FormErrorMessage,
} from "@chakra-ui/react";
import { createAdmin, getCompanies } from "../../../redux/actions/adminActions";
import { useToast } from "@chakra-ui/react";
import { connect } from "react-redux";
// import { Select as Autocomplete } from "react-select";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
// import Autocomplete from "react-autocomplete";

import Autocomplete from "autocomplete-react-minimalist";
const CreateAdminModal = (props) => {
  const { isOpen, onClose } = props;
  const [companyRegistrationId, setCompanyRegistrationId] = useState("");
  const dispatch = useDispatch();
  const toast = useToast();
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
    name: Yup.string().required("Required"),
    companyId: Yup.string().required("Required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Required"),
    role: Yup.string()
      .oneOf(["user", "admin", "superadmin"], "Invalid role")
      .required("Required"),
  });

  const items = [
    {
      id: 0,
      name: "Cobol",
    },
    {
      id: 1,
      name: "JavaScript",
    },
    {
      id: 2,
      name: "Basic",
    },
    {
      id: 3,
      name: "PHP",
    },
    {
      id: 4,
      name: "Java",
    },
  ];
  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      password: "",
      role: "user",
      companyId: "",
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(createAdmin(values));
      onClose();
    },
  });

  useEffect(() => {
    setCompanyRegistrationId("");
    if (!isOpen) {
      formik.resetForm();
    }
  }, [isOpen]);

  useEffect(() => {
    if (props.isError) {
      toast({
        position: "top",
        title: "Failure",
        description: props.errorMessage,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else if (props.message !== "") {
      toast({
        position: "top",
        title: "Success",
        description: props.message,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [props.message, props.error]);

  useEffect(() => {
    setCompanyRegistrationId("");
    dispatch(getCompanies());
  }, []);

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results);
  };

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result);
  };

  const handleOnSelect = async (item) => {
    // the item selected
    console.log(item);
    await formik.setFieldValue("companyId", item.companyId);
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };

  const formatResult = (item) => {
    return (
      <>
        {/* <span style={{ display: "block", textAlign: "left" }}>
          id: {item.id}
        </span> */}
        <span style={{ display: "block", textAlign: "left" }}>{item.name}</span>
      </>
    );
  };

  const formatGroupLabel = (data) => (
    <div>
      <span>{data.companyName}</span>
      {/* <span style={groupBadgeStyles}>{data.options.length}</span> */}
    </div>
  );

  // const onCompanyChange = (e) => {
  //   const filtered = props.companies.filter(
  //     (item) => item.companyRegistrationId === e.target.value
  //   );
  //   if (filtered.length > 0) alert(filtered[0].companyId);
  //   formik.setFieldValue("companyId", filtered[0].companyId);
  // };

  const onCompanyItemChange = async (e, companyRegistrationId) => {
    await setCompanyRegistrationId(companyRegistrationId);
    const filtered = props.companies.filter(
      (item) => item.companyRegistrationId === companyRegistrationId
    );
    if (filtered.length > 0)
      await formik.setFieldValue("companyId", filtered[0]._id);
  };

  const onCompanyChange = async (e) => {
    await setCompanyRegistrationId(e.target.value);
    const filtered = props.companies.filter(
      (item) => item.companyRegistrationId === e.target.value
    );
    if (filtered.length > 0) {
      await formik.setFieldValue("companyId", filtered[0]._id);
    }
  };

  const onKeyDown = async (e, companyRegistrationId) => {
    if (e.key === "Enter") {
      await setCompanyRegistrationId(companyRegistrationId);
      const filtered = props.companies.filter(
        (item) => item.companyRegistrationId === companyRegistrationId
      );
      if (filtered.length > 0)
        await formik.setFieldValue("companyId", filtered[0]._id);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent backgroundColor={"white"}>
        <ModalHeader color={"yellow.400"}>Create Admin</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={formik.handleSubmit}>
          <ModalBody pb={6}>
            <FormControl
              isInvalid={formik.errors.email && formik.touched.email}
            >
              <FormLabel color={"yellow.400"}>Email</FormLabel>
              <Input
                placeholder="Email"
                _placeholder={{ color: "yellow.400" }}
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
            </FormControl>

            <FormControl
              mt={4}
              isInvalid={formik.errors.name && formik.touched.name}
            >
              <FormLabel color={"yellow.400"}>Name</FormLabel>
              <Input
                placeholder="Name"
                _placeholder={{ color: "yellow.400" }}
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
            </FormControl>

            {/* <Autocomplete
              inputKey="my-autocomplete"
              placeholder="Type to search..."
              listToFilter={props.companies}
              modifierClass=""
              isRestrictedToOption={true}
              onChange={(value) => console.log(value)}
              notFoundMessage="No results found."
            /> */}

            {/* <Autocomplete
              formatGroupLabel={formatGroupLabel}
              options={props.companies}
            ></Autocomplete> */}
            {/* <Autocomplete
              value={formik.values.companyId}
              items={props.companies}
              getItemValue={(item) => item.title}
              // shouldItemRender={renderMovieTitle}
              renderMenu={(item) => <div className="dropdown">{item}</div>}
              renderItem={(item, isHighlighted) => (
                <div className={`item ${isHighlighted ? "selected-item" : ""}`}>
                  {item.title}
                </div>
              )}
              onChange={(event, val) => this.setState({ val })}
              onSelect={(val) => this.setState({ val })}
            /> */}
            {/* <ReactSearchAutocomplete
              className=""
              placeholder="Search company by reg id. Ex: 44845430"
              items={items}
              onSearch={handleOnSearch}
              onHover={handleOnHover}
              onSelect={handleOnSelect}
              onFocus={handleOnFocus}
              autoFocus
              formatResult={formatResult}
            /> */}

            <FormLabel color={"yellow.400"}>Company Id</FormLabel>
            <AutoComplete openOnFocus>
              <AutoCompleteInput
                value={companyRegistrationId}
                onChange={onCompanyChange}
                // onChange={onCompanyChange}
                // value={companyRegistrationId}
                // onBlur={formik.handleBlur}
                // onChange={formik.handleChange}
                // value={formik.values.companyRegistrationId}
                placeholder="Search company by reg id. Ex: 44845430"
              />
              <AutoCompleteList>
                {props.companies?.map((company, cid) => (
                  <AutoCompleteItem
                    key={`option-${cid}`}
                    value={company.companyRegistrationId}
                    textTransform="capitalize"
                    onClick={(e) =>
                      onCompanyItemChange(e, company.companyRegistrationId)
                    }
                    onKeyDown={(e) =>
                      onKeyDown(e, company.companyRegistrationId)
                    }
                  >
                    {company.companyName}
                  </AutoCompleteItem>
                ))}
              </AutoCompleteList>
            </AutoComplete>

            <FormControl
              mt={4}
              isInvalid={formik.errors.password && formik.touched.password}
            >
              <FormLabel color={"yellow.400"}>Password</FormLabel>
              <Input
                type="password"
                placeholder="Password"
                _placeholder={{ color: "yellow.400" }}
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
            </FormControl>

            <FormControl
              mt={4}
              isInvalid={formik.errors.role && formik.touched.role}
            >
              <FormLabel color={"yellow.400"}>Role</FormLabel>
              <Select
                color="white"
                bg="darkorange"
                colorScheme="white"
                name="role"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.role}
              >
                <option selected hidden disabled value="">
                  Select role
                </option>
                <option
                  style={{ backgroundColor: "darkorange", color: "white" }}
                  color="darkorange"
                  value="user"
                >
                  User
                </option>
                <option
                  style={{ backgroundColor: "darkorange", color: "white" }}
                  color="darkorange"
                  value="admin"
                >
                  Admin
                </option>
                <option
                  style={{ backgroundColor: "darkorange", color: "white" }}
                  color="darkorange"
                  value="superadmin"
                >
                  Superadmin
                </option>
              </Select>
              <FormErrorMessage>{formik.errors.role}</FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="yellow" borderRadius={0} mr={3} type="submit">
              Save
            </Button>
            <Button borderRadius={0} onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

function mapStateToProps(state) {
  return {
    isError: state.admin.isError,
    message: state.admin.message,
    loading: state.admin.loading,
    error: state.admin.error,
    companies: state.admin.companies,
  };
}

export default connect(mapStateToProps)(CreateAdminModal);
