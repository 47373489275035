import {
  TOGGLE_LOADING,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  RESEND_EMAIL_SUCCESS,
  RESEND_EMAIL_FAIL,
  SENT_RESET_PASSWORD_FAIL,
  SENT_RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_EMAIL_SENT,
} from "../../constants/project";

const _ = require("lodash");

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  loading: true,
  user: null,
  error: null,
  emailSent: false,
  isError: false,
  resetLinkSent: { value: false, date: null },
  resetPassword: { value: false, date: null },
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_LOADING:
      return {
        ...state,
        ...payload,
        loading: payload.loading,
        isError: false,
        error: null,
      };
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      localStorage.setItem("token", payload.token);
      localStorage.setItem("user", JSON.stringify(payload.user));
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        emailSent: { value: true, date: new Date() },
        loading: false,
        user: _.cloneDeep(payload.user),
        isError: false,
      };
    case LOGOUT_SUCCESS:
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      return {
        ...state,
        ...payload,
        isAuthenticated: false,
        token: null,
        emailSent: true,
        loading: false,
        user: null,
        isError: false,
      };
    case RESEND_EMAIL_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
        emailSent: { value: true, date: new Date() },
        isError: false,
      };
    case RESEND_EMAIL_FAIL:
      return {
        ...state,
        ...payload,
        emailSent: { value: false, date: new Date() },
        loading: false,
        isError: true,
        error: payload,
      };
    case SENT_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
        resetLinkSent: { value: true, date: new Date() },
        isError: false,
        error: null,
      };
    case SENT_RESET_PASSWORD_FAIL:
      return {
        ...state,
        ...payload,
        resetLinkSent: { value: false, date: new Date() },
        loading: false,
        isError: true,
        error: payload.message,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
        resetPassword: { value: true, date: new Date() },
        isError: false,
        error: null,
      };
    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        ...payload,
        resetPassword: { value: false, date: new Date() },
        loading: false,
        isError: true,
        error: payload.message,
      };
    case REGISTER_FAIL:
    case LOGIN_FAIL:
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      return {
        ...state,
        token: null,
        emailSent: { value: false, date: new Date() },
        isAuthenticated: false,
        loading: false,
        error: payload.message,
        isError: true,
      };
    case RESET_EMAIL_SENT:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        emailSent: false,
        loading: false,
        error: null,
        isError: false,
      };
    default:
      return state;
  }
};

export default authReducer;
