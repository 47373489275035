import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { Flex } from "@chakra-ui/react";
import { SiOpenai } from "react-icons/si";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  Box,
  Text,
  Checkbox,
  Select,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  UnorderedList,
  ListItem,
  Spinner,
} from "@chakra-ui/react";
import { connect, useDispatch } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { useFormik } from "formik";

import {
  getCampaignsProspects,
  getConversationDetails,
  getRecording,
  setProspectsStatus,
} from "../redux/actions/crmActions";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  MessageSeparator,
} from "@chatscope/chat-ui-kit-react";
import { CRMSchema } from "../validationSchemas/CRMSchema";

const CRM = (props) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [currentProspectStatus, setCurrentProspectStatus] = useState("");
  const [campaignCheckedUsers, setCampaignCheckedUsers] = useState([]);

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const { conversations, recordings } = props;
  const [currentPage, setCurrentPage] = useState(0);
  const [campaignStartDate, setCampaignStartDate] = useState();
  const [campaignEndDate, setCampaignEndDate] = useState();
  const [campaignType, setCampaignType] = useState("");
  const [whatsappChecked, setWhatsappChecked] = useState(true);
  const [emailChecked, setEmailChecked] = useState(false);
  const [voiceChecked, setVoiceChecked] = useState(true);
  const rowsPerPage = 5; // You can adjust this number as needed
  const [uniqueProspects, setUniqueProspects] = useState([]);

  // const numPages = Math.ceil(uniqueProspects?.length / rowsPerPage);
  const [numPages, setNumPages] = useState();
  const [currentPageData, setCurrentPageData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const [whatsappConversations, setWhatsappConversations] = useState([]);
  const [voiceConversations, setVoiceConversations] = useState([]);
  const [emailConversations, setEmailConversations] = useState([]);
  const [updatedProspects, setUpdatedProspects] = useState([]);

  // const numPages = Math.ceil(uniqueProspects?.length / rowsPerPage);
  // const currentPageData = uniqueProspects?.slice(
  //   currentPage * rowsPerPage,
  //   (currentPage + 1) * rowsPerPage
  // );

  const nextPage = () => {
    setCurrentPage((current) => Math.min(current + 1, numPages - 1));
  };

  const previousPage = () => {
    setCurrentPage((current) => Math.max(current - 1, 0));
  };

  const formik = useFormik({
    initialValues: {
      prospects: [],
    },
    onSubmit: (values) => {
      dispatch(
        setProspectsStatus({
          prospects: values.prospects,
        })
      );
    },
    validationSchema: CRMSchema,
  });

  // useEffect(() => {
  //   // dispatch(getCampaignProspects(campaignStartDate, campaignEndDate));
  // }, []);

  useEffect(() => {
    console.log("prospectssss");
    console.log(props.prospects);
    if (props.isError) {
      toast({
        position: "top",
        title: "Failure",
        description: props.errorMessage,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else if (props.message !== "") {
      toast({
        position: "top",
        title: "Success",
        description: props.message,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }

    const key = "phoneNumber";

    const arrayUniqueByKey = [
      ...new Map(props.prospects.map((item) => [item[key], item])).values(),
    ];

    console.log(arrayUniqueByKey);

    setUniqueProspects((old) => {
      return [...arrayUniqueByKey];
    });
  }, [
    props.prospects,
    props.isError,
    props.errorMessage,
    props.loading,
    props.message,
  ]);

  useEffect(() => {
    setNumPages(Math.ceil(uniqueProspects?.length / rowsPerPage));
    setCurrentPageData(
      uniqueProspects?.slice(
        currentPage * rowsPerPage,
        (currentPage + 1) * rowsPerPage
      )
    );
    // const currentPageData = uniqueProspects?.slice(
    //   currentPage * rowsPerPage,
    //   (currentPage + 1) * rowsPerPage
    // );
  }, [uniqueProspects, currentPage]);
  useEffect(() => {
    if (selected)
      dispatch(
        getConversationDetails(
          campaignStartDate,
          campaignEndDate,
          selected.phoneNumber,
          selected.email
        )
      );
  }, [selected]);

  const setProspects = async () => {
    await formik.setFieldValue("prospects", updatedProspects);
  };

  useEffect(() => {
    setProspects();
  }, [updatedProspects]);

  useEffect(() => {
    console.log("conversations, recordings: ", conversations, recordings);

    const whatsappConversations = conversations.filter(
      (conversation) => conversation.conversationType === "whatsapp"
    );

    const voiceConversations = conversations.filter(
      (conversation) => conversation.conversationType === "voice"
    );

    const emailConversations = conversations.filter(
      (conversation) => conversation.conversationType === "email"
    );

    setWhatsappConversations(
      whatsappConversations.length > 0
        ? whatsappConversations.sort((a, b) => {
            return new Date(a.createdAt) - new Date(b.createdAt);
          })
        : whatsappConversations
    );

    setVoiceConversations(
      voiceConversations.length > 0
        ? voiceConversations.sort((a, b) => {
            return new Date(a.createdAt) - new Date(b.createdAt);
          })
        : voiceConversations
    );

    setEmailConversations(
      emailConversations.length > 0
        ? emailConversations.sort((a, b) => {
            return new Date(a.createdAt) - new Date(b.createdAt);
          })
        : emailConversations
    );
  }, [conversations, recordings]);

  const handleDateSelect = (ranges) => {
    console.log(ranges);

    const newSelectionRange = {
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
      key: "selection",
    };
    setSelectionRange(newSelectionRange);
    setCampaignStartDate(ranges.selection.startDate);
    setCampaignEndDate(ranges.selection.endDate);
    dispatch(
      getCampaignsProspects(
        ranges.selection.startDate,
        ranges.selection.endDate
      )
    );

    setUpdatedProspects([]);
    // formik.setFieldValue("startDate", ranges.selection.startDate);
    // formik.setFieldValue("endDate", ranges.selection.endDate);

    // dispatch(
    //   getConversationDetails(
    //     ranges.selection.startDate,
    //     ranges.selection.endDate
    //   )
    // );
  };

  const MessageConversations = () => {
    let messageDirection = "incoming";
    let conversations = [];

    switch (campaignType) {
      case "voice":
        conversations = [...voiceConversations];
        break;
      case "whatsapp":
        conversations = [...whatsappConversations];
        break;
      case "email":
        conversations = [...emailConversations];
        break;
      default:
        break;
    }
    return conversations.map((conversation, key) => {
      if (key > 0) {
        messageDirection =
          conversations[key - 1].from !== conversation.from
            ? messageDirection === "incoming"
              ? "outgoing"
              : "incoming"
            : messageDirection;
      }
      return (
        <>
          <Message
            model={{
              message: conversation.text,
              sentTime: conversation.createdAt,
              sender: conversation.from,
              direction: messageDirection,
            }}
          />
          <MessageSeparator>
            {new Date(conversation.createdAt).toLocaleString()}
          </MessageSeparator>
        </>
      );
    });
  };

  // const handleDateSelect = async (ranges) => {
  //   console.log(ranges);
  //   ranges.selection.startDate.setHours(
  //     ranges.selection.startDate.getHours() + 4
  //   );
  //   ranges.selection.endDate.setHours(
  //     ranges.selection.endDate.getHours() + 4
  //   );

  //   const newSelectionRange = {
  //     startDate: ranges.selection.startDate,
  //     endDate: ranges.selection.endDate,
  //     key: "selection",
  //   };

  //   await setSelectionRange(newSelectionRange);
  //   await setCampaignStartDate(ranges.selection.startDate);
  //   await setCampaignEndDate(ranges.selection.endDate);

  //   await formik.setFieldValue("startDate", ranges.selection.startDate);
  //   await formik.setFieldValue("endDate", ranges.selection.endDate);
  // };

  const handleProspectStatusChange = async (e, prospect) => {
    // await setCurrentProspectStatus(e.target.value);

    const foundProspect = updatedProspects.find(
      (item) => item._id === prospect._id
    );
    if (foundProspect) {
      foundProspect.status = prospect.status;
      await setUpdatedProspects((prevState) => {
        return updatedProspects.map((el) =>
          el._id === foundProspect._id ? { ...el, status: e.target.value } : el
        );
      });
    } else {
      await setUpdatedProspects((old) => {
        return [...old, { ...prospect, status: e.target.value }];
      });
    }

    console.log(updatedProspects);
    // formik.setFieldValue("prospects", updatedProspects);
  };

  const handleProspectStatusStyleChange = (updatedRow) => {
    switch (updatedRow?.status) {
      case "NotContacted":
        return { backgroundColor: "darkorange", color: "white" };
      case "AnsweredYes":
        return { backgroundColor: "green", color: "black" };
      case "AnsweredNo":
        return { backgroundColor: "red", color: "black" };
      case "FollowUp":
        return { backgroundColor: "yellow", color: "black" };
      default:
        return { backgroundColor: "white", color: "darkorange" };
    }
  };

  return (
    <div>
      <Navbar></Navbar>
      <div>
        <Flex
          flexDirection="column"
          width="100wh"
          height="100wh"
          backgroundColor="white"
          justifyContent="center"
          alignItems="center"
        >
          <h1
            style={{
              textAlign: "center",
              fontWeight: "bold",
              color: "orange",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            Conversation Details And Audio Files
          </h1>

          <Flex justifyContent={"center"} flexDirection="column">
            <h1
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "orange",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              Please select date range for campaign(start date and end date)
            </h1>
            <DateRange ranges={[selectionRange]} onChange={handleDateSelect} />
          </Flex>

          <form onSubmit={formik.handleSubmit}>
            <TableContainer>
              <Table variant="simple">
                <TableCaption color="orange"></TableCaption>
                <Thead>
                  <Tr>
                    <Th></Th>
                    <Th color={"gray"}>Name</Th>
                    <Th color={"gray"}>First Name</Th>
                    <Th color={"gray"} isNumeric>
                      Phone Number
                    </Th>
                    <Th color={"gray"}>Email</Th>
                    <Th color={"gray"}>Prospect Status</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {currentPageData?.map((user, index) => {
                    const updatedRow = updatedProspects.find(
                      (item) => user._id === item._id
                    );

                    const rowStyle = handleProspectStatusStyleChange(user);

                    return (
                      <Tr
                        key={index}
                        style={{
                          backgroundColor: rowStyle.backgroundColor,
                          color: rowStyle.color,
                        }}
                      >
                        <Td
                          fontWeight={"bold"}
                          onClick={() => {
                            setIsOpen(true);
                            setSelected(user);
                          }}
                        >
                          <Button
                            borderRadius={0}
                            mr={5}
                            onClick={previousPage}
                            disabled={currentPage === 0}
                            colorScheme="transparent"
                          >
                            <SiOpenai color="blue"></SiOpenai>
                          </Button>
                        </Td>
                        <Td
                          fontWeight={"bold"}
                          onClick={() => {
                            setIsOpen(true);
                            setSelected(user);
                          }}
                        >
                          {user.name}
                        </Td>
                        <Td
                          fontWeight={"bold"}
                          onClick={() => {
                            setIsOpen(true);
                            setSelected(user);
                          }}
                        >
                          {user.firstName}
                        </Td>
                        <Td
                          fontWeight={"bold"}
                          onClick={() => {
                            setIsOpen(true);
                            setSelected(user);
                          }}
                          isNumeric
                        >
                          {user.phoneNumber}
                        </Td>
                        <Td
                          fontWeight={"bold"}
                          onClick={() => {
                            setIsOpen(true);
                            setSelected(user);
                          }}
                          isNumeric
                        >
                          {user.email}
                        </Td>
                        <Td color={"yellow"} isNumeric>
                          <Select
                            marginTop={10}
                            marginbottom={10}
                            width={"fit-content"}
                            color={"white"}
                            // placeholder="Select campaign type"
                            // _placeholder={{ backgroundColor: "black", color: "yellow" }}
                            bg="darkorange"
                            colorScheme={"white"}
                            onChange={(e) =>
                              handleProspectStatusChange(e, user)
                            }
                          >
                            {/* <option selected hidden disabled value="">
                            Select file type
                          </option> */}

                            <option
                              selected={user.status === "NotContacted"}
                              style={{
                                backgroundColor: "black",
                                color: "white",
                              }}
                              color="black"
                              value={"NotContacted"}
                            >
                              Not Contacted
                            </option>
                            <option
                              selected={user.status === "AnsweredYes"}
                              style={{
                                backgroundColor: "green",
                                color: "black",
                              }}
                              color="black"
                              value={"AnsweredYes"}
                            >
                              Answered Yes
                            </option>
                            <option
                              selected={user.status === "FollowUp"}
                              style={{
                                backgroundColor: "yellow",
                                color: "black",
                              }}
                              color="black"
                              value={"FollowUp"}
                            >
                              Needs Follow Up
                            </option>
                            <option
                              selected={user.status === "AnsweredNo"}
                              style={{
                                backgroundColor: "red",
                                color: "black",
                              }}
                              color="black"
                              value={"AnsweredNo"}
                            >
                              Answered No
                            </option>
                          </Select>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
                <Tfoot>
                  <Td>
                    <Text fontWeight={"bold"} color={"grey"}>
                      Page: {currentPage + 1}
                    </Text>
                    {/* <Text color={"grey"}>Total: {numPages}</Text> */}
                  </Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td>
                    <Text fontWeight={"bold"} color={"grey"}>
                      Total pages: {numPages}
                    </Text>
                    {/* <Text color={"grey"}>Total: {numPages}</Text> */}
                  </Td>
                </Tfoot>
              </Table>
            </TableContainer>
            <Box display="flex" justifyContent="space-between" mt="4">
              <Button
                borderRadius={0}
                mr={5}
                onClick={previousPage}
                disabled={currentPage === 0}
                colorScheme="yellow"
              >
                Previous
              </Button>
              <Button
                colorScheme="yellow"
                borderRadius={0}
                onClick={nextPage}
                disabled={currentPage >= numPages - 1}
              >
                Next
              </Button>
            </Box>

            <div>
              {formik.errors.prospects ? (
                <p style={{ color: "red" }}>{formik.errors.prospects}</p>
              ) : null}
            </div>

            <Flex marginTop={10} marginBottom={15} justifyContent={"center"}>
              <Button
                borderRadius={0}
                type="submit"
                variant="solid"
                colorScheme="yellow"
                width="30"
              >
                {/* {!loading && <Spinner mr={2} />} */}
                Save Prospects status
              </Button>
            </Flex>
          </form>
        </Flex>
      </div>

      {/* Adaugă funcționalitatea pentru configurarea campaniilor aici */}

      <Modal
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
        onClick={() => setIsOpen(false)}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent backgroundColor={"white"}>
          <ModalHeader color={"yellow.400"}>Conversation history</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Stack>
              <Button
                colorScheme="yellow"
                borderRadius={0}
                onClick={() =>
                  setCampaignType(campaignType === "voice" ? "" : "voice")
                }
              >
                {campaignType === "voice" ? "Hide" : "Show"} Voice Conversations
                And Audio
              </Button>
              <Button
                colorScheme="green"
                borderRadius={0}
                onClick={() =>
                  setCampaignType(campaignType === "whatsapp" ? "" : "whatsapp")
                }
              >
                {campaignType === "whatsapp" ? "Hide" : "Show"} Whatsapp
                Conversations
              </Button>
              <Button
                colorScheme="blue"
                borderRadius={0}
                onClick={() =>
                  setCampaignType(campaignType === "email" ? "" : "email")
                }
              >
                {campaignType === "email" ? "Hide" : "Show"} Email Conversations
              </Button>

              <Button
                color={"white"}
                bg={"darkorange"}
                borderRadius={0}
                onClick={() =>
                  setCampaignType(
                    campaignType === "recordings" ? "" : "recordings"
                  )
                }
              >
                {campaignType === "recordings" ? "Hide" : "Show"} Recordings
              </Button>
            </Stack>

            <MainContainer>
              <ChatContainer>
                <MessageList>{MessageConversations()}</MessageList>
              </ChatContainer>
            </MainContainer>

            {campaignType === "recordings" ? (
              <>
                <Text
                  marginTop="10px"
                  fontWeight={"bold"}
                  textAlign="center"
                  color={"orange"}
                >
                  Recordings
                </Text>
                <UnorderedList styleType="'-'">
                  {recordings &&
                    recordings.map((recording, key) => {
                      return (
                        <ListItem
                          alignSelf={"center"}
                          key={key}
                          marginTop={"20px"}

                          // onClick={() => {
                          //   dispatch(getRecording(recording.recordingSid));
                          // }}
                        >
                          <Button
                            borderRadius={0}
                            color={"orange"}
                            onClick={() => {
                              dispatch(getRecording(recording.recordingSid));
                            }}
                          >
                            {props.loadingRecording[recording.recordingSid] && (
                              <Spinner mr={2} />
                            )}
                            Get recording {recording.recordingSid}
                          </Button>
                          {/* {recording.recordingSid} */}
                          {recording.recordingUrl && (
                            <AudioPlayer url={recording.recordingUrl} />
                          )}
                        </ListItem>
                      );
                    })}
                </UnorderedList>
              </>
            ) : (
              ""
            )}
          </ModalBody>
          <ModalFooter>
            <Button borderRadius={0} onClick={() => setIsOpen(false)}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

const AudioPlayer = ({ url }) => {
  return (
    <div>
      <audio controls>
        <source src={url} type="audio/wav" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
    prospects: state.crm.prospects,
    recordings: state.crm.recordings,
    conversations: state.crm.conversations,
    isError: state.crm.isError,
    errorMessage: state.crm.errorMessage,
    message: state.crm.message,
    loading: state.crm.loading,
    loadingRecording: state.crm.loadingRecording,
  };
}
export default connect(mapStateToProps)(CRM);
