import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Heading,
  Stack,
  IconButton,
  Badge,
  Text,
} from "@chakra-ui/react";
import { FaEdit, FaSearch, FaEraser } from "react-icons/fa";
import { useSelector } from "react-redux";

const AdminCard = ({ admin, onClick }) => {
  const { user } = useSelector((state) => state.auth);

  return (
    <Card
      direction={{ base: "column", sm: "row" }}
      overflow="hidden"
      variant="outline"
      backgroundColor="white"
      color="black"
    >
      <Stack>
        <CardBody>
          <Heading size="md">
            {admin.name}
            <Badge
              ml="5"
              bg={admin.role === "superadmin" ? "blue.500" : "green.500"}
            >
              {admin.role}
            </Badge>
          </Heading>

          <Text py="2">{admin.email}</Text>
        </CardBody>

        <CardFooter>
          <Stack flexDir="row" justifyContent="end" alignItems="end">
            <IconButton
              colorScheme="yellow"
              aria-label="Search database"
              icon={<FaSearch />}
              onClick={() => onClick("show", true, admin._id)}
            />
            <IconButton
              colorScheme="green"
              aria-label="Edit admin"
              icon={<FaEdit />}
              onClick={() => onClick("edit", true, admin._id)}
              disabled={user?.role !== "superadmin"}
            />
            <IconButton
              colorScheme="red"
              aria-label="Delete admin"
              icon={<FaEraser />}
              onClick={() => onClick("delete", true, admin._id)}
              disabled={user?.role !== "superadmin"}
            />
          </Stack>
        </CardFooter>
      </Stack>
    </Card>
  );
};

export default AdminCard;
