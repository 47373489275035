import {
  CLEAR_AI_INSTRUCTIONS,
  DatabaseFileType,
  GET_AI_INSTRUCTIONS_FAIL,
  GET_AI_INSTRUCTIONS_SUCCESS,
  SAVE_AI_INSTRUCTIONS_FAIL,
  SAVE_AI_INSTRUCTIONS_SUCCESS,
} from "../../constants/project";
import api from "../../utils";
import {
  TOGGLE_LOADING,
  UPLOAD_SUCCESS,
  UPLOAD_FAIL,
  UPLOAD_FINISHED,
  GET_PARSED_USERS_SUCCESS,
  GET_PARSED_USERS_FAIL,
  LOGOUT_SUCCESS,
} from "../../constants/project";

// Action creators
export const toggleLoading = (loading) => ({
  type: TOGGLE_LOADING,
  payload: loading,
});

export const upload =
  (files, userId, uploadType, companyId) => async (dispatch) => {
    dispatch(toggleLoading(true));
    try {
      console.log(files);
      const formData = new FormData();
      Array.from(files).forEach((file) => {
        formData.append("files", file);
      });
      formData.append("userId", userId);
      formData.append("companyId", companyId);
      console.log(formData);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      const res =
        uploadType === DatabaseFileType.pdfOfferFile
          ? await api.post("/api/data/uploadPdfOffer", formData, config)
          : await api.post("/api/data/upload", formData, config);
      if (res) dispatch({ type: UPLOAD_SUCCESS, payload: res.data });
    } catch (error) {
      dispatch({ type: UPLOAD_FAIL, payload: error.response.data });
      if (error.response.status === 401) {
        dispatch({ type: LOGOUT_SUCCESS });
      }
    }
  };

export const saveAiInstructions = (aiData) => async (dispatch) => {
  dispatch(toggleLoading(true));
  try {
    const res = await api.put("/api/data/saveAiInstructions", aiData);
    if (res) {
      dispatch({ type: SAVE_AI_INSTRUCTIONS_SUCCESS, payload: res.data });
    }
  } catch (error) {
    dispatch({
      type: SAVE_AI_INSTRUCTIONS_FAIL,
      payload: error.response.data,
    });
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const getAiInstructions = (userData) => async (dispatch) => {
  dispatch(toggleLoading(true));
  dispatch({ type: CLEAR_AI_INSTRUCTIONS });
  try {
    const res = await api.get("/api/data/getAiInstructions", {
      params: {
        userId: userData.userId,
      },
    });

    console.log(res.data);
    if (res) {
      dispatch({ type: GET_AI_INSTRUCTIONS_SUCCESS, payload: res.data });
    }
  } catch (error) {
    dispatch({
      type: GET_AI_INSTRUCTIONS_FAIL,
      payload: error.response.data,
    });
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const uploadFail = () => async (dispatch) => {
  dispatch({ type: UPLOAD_FAIL });
};

export const uploadFinished = () => async (dispatch) => {
  dispatch({ type: UPLOAD_FINISHED });
};

export const getParsedUsersFromFiles = (userId) => async (dispatch) => {
  dispatch(toggleLoading(true));
  try {
    const res = await api.get("/api/data/getParsedUsersFromFiles", {
      params: {
        userId: userId,
      },
    });

    console.log(res.data);
    if (res) {
      dispatch({ type: GET_PARSED_USERS_SUCCESS, payload: res.data });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_PARSED_USERS_FAIL,
      payload: error.response.data,
    });
    if (error.response.status === 401) {
      dispatch({ type: LOGOUT_SUCCESS });
    }
  } finally {
    dispatch(toggleLoading(false));
  }
};
