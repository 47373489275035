import React, { useEffect, useState, useRef } from "react";
import {
  Flex,
  Input,
  useToast,
  Select,
  Textarea,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import {
  Button,
  FormControl,
  FormLabel,
  Icon,
  Divider,
} from "@chakra-ui/react";
import { FiFile } from "react-icons/fi";
import * as Yup from "yup";
import FileUpload from "./FileUpload";
import { useFormik } from "formik";
import { useSelector, useDispatch, connect } from "react-redux";
import {
  getAiInstructions,
  saveAiInstructions,
  upload,
  uploadFinished,
} from "../redux/actions/dataActions";
import { DatabaseFileType } from "../constants/project";
import Navbar from "./Navbar";
import axios from "axios";
import { FaTools } from "react-icons/fa";
import { diffMinutes, diffSeconds } from "helpers";

const FileUploadSchema = Yup.object().shape({
  myFile: Yup.mixed().required("A file is required"),
});

function Database(props) {
  const [fileName, setFileName] = useState([]);
  const [aiFile, setAiFile] = useState();
  const [fileType, setFileType] = useState(DatabaseFileType.prospectsFile);
  const [aiInstructions, setAiInstructions] = useState("");
  const [aiHelloOpener, setAiHelloOpener] = useState("");
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const toast = useToast();

  const formik = useFormik({
    initialValues: {
      myFile: null,
    },
    validationSchema: FileUploadSchema,
    onSubmit: async (values) => {
      console.log("Logging values from onSubmit: ", values);
      try {
        await dispatch(
          upload(values.myFile, user._id, fileType, props.companyId)
        );
        console.log("Upload perfect asset ran");
      } catch (error) {
        console.error("Error during file upload:", error);
      }
    },
  });

  const firstUpdate = useRef(true);

  useEffect(() => {
    if (
      !props.saveAiInstructions?.value &&
      diffSeconds(props.saveAiInstructions?.date) < 3
    ) {
      toast({
        position: "top",
        title: "Failure",
        description: "Save failed",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    if (
      props.saveAiInstructions?.value &&
      diffSeconds(props.saveAiInstructions?.date) < 3
    ) {
      toast({
        position: "top",
        title: "Success",
        description: "Save succeeded",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [props.saveAiInstructions]);

  useEffect(() => {
    if (user._id) dispatch(getAiInstructions({ userId: user._id }));
  }, [user._id]);

  useEffect(() => {
    setAiInstructions(
      props.aiData?.aiInstructions ? props.aiData?.aiInstructions : ""
    );
    setAiHelloOpener(
      props.aiData?.aiHelloOpener ? props.aiData?.aiHelloOpener : ""
    );
  }, [props.aiData]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    console.log(props.errorMessage);
    if (props.fileSent) {
      toast({
        position: "top",
        title: "File Sent.",
        description:
          "Congratulations! The file was successfully saved into the database",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else if (props.isError) {
      toast({
        position: "top",
        title: "Failure",
        description: props.errorMessage,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [props.fileSent, props.isError, props.loading, props.errorMessage, toast]);

  const onChange = (event) => {
    const { files } = event.target;
    if (files) {
      formik.setFieldValue("myFile", files);
      setFileName(Array.from(files).map((file) => file.name));
    }
  };

  const onAiFileChange = (event) => {
    const { files } = event.target;
    if (files) {
      // formik.setFieldValue("myFile", files);
      setAiFile(files[0]);
    }
    console.log(aiFile);
  };

  const handleFileTypeChange = (e) => {
    setFileType(e.target.value);
  };

  const handleAiInstructionsChange = (e) => {
    setAiInstructions(e.target.value);
  };

  const handleAiHelloOpenerChange = (e) => {
    setAiHelloOpener(e.target.value);
  };

  const addVoiceToElevenLabsAi = (e) => {
    const form = new FormData();
    form.append("name", "Ai voice upload");
    const files = [];
    files.push(new Blob([aiFile], { type: "audio/mp3" }));
    form.append("files", files);
    form.append(
      "description",
      "this is the voice of a romanian sales agent, Mihai"
    );
    form.append("labels", "romanian sales agent");

    const options = {
      method: "POST",
    };

    options.body = form;

    fetch("https://api.elevenlabs.io/v1/voices/add", options)
      .then((response) => response.json())
      .then((response) => console.log(response))
      .catch((err) => console.error(err));
    // fetch("https://api.elevenlabs.io/v1/voices/add", options)
    //   .then((response) => response.json())
    //   .then((response) => console.log(response))
    //   .catch((err) => console.error(err));
  };

  return (
    <div className="database-container container">
      <Navbar />
      <Flex
        flexDirection="column"
        height="100vh"
        backgroundColor="white"
        justifyContent="top"
        alignItems="center"
      >
        {/* <h1
          style={{
            textAlign: "center",
            fontWeight: "bold",
            color: "gray",
            marginBottom: 20,
          }}
        >
          Database
        </h1> */}
        <>
          <Flex
            flexDir={"column"}
            marginTop="20px"
            alignItems={"center"}
            justifyContent={"center"}
          >
            <h1
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "gray",
                marginBottom: 20,
              }}
            >
              Instructions for AI Asssistant
            </h1>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                return false;
              }}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Flex
                flexDir={"column"}
                marginTop="20px"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <FormControl>
                  <InputGroup>
                    {/* <InputLeftElement
                    pointerEvents="none"
                    children={<FaTools color="gray.500" />}
                  /> */}
                    <Textarea
                      minWidth={"150px"}
                      minHeight={"150px"}
                      marginBottom={"10px"}
                      name="aiHelloOpener"
                      value={aiHelloOpener}
                      onChange={handleAiHelloOpenerChange}
                      type="email"
                      placeholder="AI Helllo Opener"
                      color={"black"}
                      _placeholder={{ color: "gray.500" }}
                    />
                  </InputGroup>
                </FormControl>

                <FormControl>
                  <InputGroup>
                    {/* <InputLeftElement
                    pointerEvents="none"
                    children={<FaTools color="gray.500" />}
                  /> */}
                    <Textarea
                      minWidth={"300px"}
                      minHeight={"300px"}
                      name="aiInstructions"
                      value={aiInstructions}
                      onChange={handleAiInstructionsChange}
                      type="email"
                      placeholder="AI Assistant instructions"
                      color={"black"}
                      _placeholder={{ color: "gray.500" }}
                    />
                  </InputGroup>
                </FormControl>

                <Button
                  mt={5}
                  borderRadius={0}
                  onClick={() =>
                    dispatch(
                      saveAiInstructions({
                        aiInstructions: aiInstructions,
                        aiHelloOpener: aiHelloOpener,
                        companyId: props.companyId,
                        userId: props.userId,
                      })
                    )
                  }
                  variant="solid"
                  colorScheme="yellow"
                  alignSelf={"center"}
                  isDisabled={aiInstructions === ""}
                >
                  Save Instructions And Opener
                </Button>
              </Flex>
            </form>
          </Flex>

          <Divider orientation="horizontal" width={20} color={"red"} />
          <h1
            style={{
              textAlign: "center",
              fontWeight: "bold",
              color: "gray",
              marginBottom: 20,
              marginTop: 20,
            }}
          >
            Upload file with prospects or company offer PDF
          </h1>
          <Select
            marginTop={10}
            marginbottom={10}
            width={"fit-content"}
            color="white"
            bg="darkorange"
            value={fileType}
            colorScheme="white"
            onChange={handleFileTypeChange}
          >
            <option hidden disabled value="">
              Select file type
            </option>
            <option
              style={{ backgroundColor: "darkorange", color: "white" }}
              color="darkorange"
              value={DatabaseFileType.prospectsFile}
            >
              Prospects file
            </option>
            <option
              style={{ backgroundColor: "darkorange", color: "white" }}
              color="darkorange"
              value={DatabaseFileType.pdfOfferFile}
            >
              PDF offer
            </option>
          </Select>

          <form onSubmit={formik.handleSubmit}>
            {/* <div>
              {formik.errors.aiInstructions ? (
                <p style={{ color: "red" }}>{formik.errors.aiInstructions}</p>
              ) : null}
            </div> */}

            <FormControl mb={5}>
              <FormLabel color={"yellow.500"}>
                {fileName.length ? fileName.join(", ") : "No File Selected"}
              </FormLabel>
              <FileUpload
                onChange={onChange}
                accept={
                  fileType === DatabaseFileType.prospectsFile
                    ? ".csv, .xlsx, .xls"
                    : "pdf"
                }
                multiple={true}
              >
                <Button leftIcon={<Icon as={FiFile} />}>Select A File</Button>
              </FileUpload>
            </FormControl>

            {formik.errors.myFile && (
              <p style={{ color: "red" }}>{formik.errors.myFile}</p>
            )}

            <Button
              borderRadius={0}
              type="submit"
              variant="solid"
              colorScheme="yellow"
              width="full"
            >
              Upload
            </Button>
          </form>
        </>
      </Flex>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    userId: state.auth.user?._id,
    companyId: state.auth.user?.companyId,
    fileSent: state.data.fileSent,
    isError: state.data.isError,
    loading: state.data.loading,
    errorMessage: state.data.errorMessage,
    saveAiInstructions: state.data.saveAiInstructions,
    aiData: state.data.aiData,
  };
}

export default connect(mapStateToProps)(Database);
