import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  login,
  register,
  sendResetPasswordLink,
} from "../redux/actions/authActions";
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Link,
  FormControl,
} from "@chakra-ui/react";
import { FaUserAlt, FaLock, FaHandPeace } from "react-icons/fa";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { ForgotPasswordSchema } from "validationSchemas/ForgotPasswordSchema";
import { EmailIcon } from "@chakra-ui/icons";
import { diffMinutes, diffSeconds } from "helpers";

const CFaUserAlt = chakra(FaUserAlt);
const ForgotPassword = (props) => {
  const dispatch = useDispatch();
  const toast = useToast();

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      dispatch(sendResetPasswordLink({ email: values.email }));
    },
    validationSchema: ForgotPasswordSchema,
  });

  useEffect(() => {
    if (
      props.resetLinkSent?.value &&
      diffSeconds(props.resetLinkSent?.date) < 3
    ) {
      toast({
        position: "top",
        title: "Success",
        description: "We sent a link by email to reset password",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else if (!props.resetLinkSent?.value && props.isError) {
      toast({
        position: "top",
        title: "Failure",
        description:
          "We're sorry, an error occurred while sending email to reset password",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [props.resetLinkSent, props.isError, props.error, toast]);

  return (
    <div style={{ color: "black" }}>
      <Flex
        flexDirection="column"
        width="100wh"
        height="100vh"
        backgroundColor="white"
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          flexDir="column"
          mb="2"
          justifyContent="center"
          alignItems="center"
        >
          <FaHandPeace color="darkorange" size={40} />
          <Heading color="darkorange">Welcome</Heading>
          <Box minW={{ base: "90%", md: "468px" }}>
            <form onSubmit={formik.handleSubmit}>
              <Stack
                spacing={4}
                p="1rem"
                backgroundColor="white"
                boxShadow="md"
              >
                <FormControl>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<EmailIcon color="gray.500" />}
                    />
                    <Input
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      type="email"
                      placeholder="email address"
                      color={"black"}
                      _placeholder={{ color: "gray.500" }}
                    />
                  </InputGroup>
                </FormControl>
                <div>
                  {formik.errors.email ? (
                    <p style={{ color: "red" }}>{formik.errors.email}</p>
                  ) : null}
                </div>

                <Button
                  borderRadius={0}
                  type="submit"
                  variant="solid"
                  colorScheme="yellow"
                  width="full"
                >
                  Send reset link
                </Button>

                <Flex>
                  <Link color="darkorange" href="/login">
                    Go Back to Login
                  </Link>
                </Flex>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Flex>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    resetLinkSent: state.auth.resetLinkSent,
    loading: state.auth.loading,
    isError: state.auth.isError,
    error: state.auth.error,
  };
}
export default connect(mapStateToProps)(ForgotPassword);
