import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import App from "./App";
import { ChakraProvider, ThemeProvider, extendTheme } from "@chakra-ui/react";
import { PersistGate } from "redux-persist/integration/react"; // Import PersistGate
import { store } from "./redux/store";
import persistor from "./redux/store";

const customIcons = {
  logoIcon: {
    viewBox: "0 0 375 375",
    path: (
      <>
        <path fill="currentColor" d="M-37.5 -37.5H412.5V412.5H-37.5z" />
        <path fill="currentColor" d="M207.434 71.496v232.008" />
        <path
          fill="currentColor"
          d="M8.406-4.594c0 .25-.11.375-.328.375H2.36v.235c0 1.023.196 1.742.594 2.156.395.418.988.625 1.781.625.5 0 1.032-.016 1.594-.047.57-.031 1.05-.078 1.438-.14h.093a.29.29 0 01.172.062c.063.031.094.094.094.187v.579a.494.494 0 01-.063.265c-.042.063-.136.106-.28.125a15.35 15.35 0 01-3.047.297 5.54 5.54 0 01-1.438-.188 3.411 3.411 0 01-1.25-.671c-.367-.313-.656-.727-.875-1.25-.211-.532-.313-1.196-.313-2v-1.532c0-1.218.32-2.187.97-2.906.655-.726 1.593-1.094 2.812-1.094 1.257 0 2.203.368 2.828 1.094.625.719.937 1.688.937 2.906zm-1.531-.765v-.157c0-.968-.188-1.656-.563-2.062-.367-.414-.921-.625-1.671-.625-.73 0-1.293.219-1.688.656-.398.43-.594 1.106-.594 2.031v.157zm0 0"
        />
        <path
          fill="currentColor"
          d="M8.734-.188c0 .126-.078.188-.234.188H7.328a.25.25 0 01-.234-.14L4.64-3.704 2.203-.172c-.043.086-.086.137-.125.156A.585.585 0 011.906 0H.891C.754 0 .687-.055.687-.172A.32.32 0 01.766-.39l3.078-4.39L.938-8.97a.462.462 0 01-.079-.219c0-.132.082-.203.25-.203h1.11c.125 0 .238.079.344.235l2.171 3.219 2.188-3.22c.07-.113.129-.179.172-.202a.439.439 0 01.187-.032h.985c.144 0 .218.07.218.204a.51.51 0 01-.078.218L5.531-4.89l3.125 4.5c.051.055.078.121.078.204zm0 0"
        />
        <path
          fill="currentColor"
          d="M8.39-4.063c0 1.344-.308 2.383-.921 3.11C6.852-.234 5.914.125 4.656.125c-.261 0-.578-.023-.953-.063-.367-.03-.71-.07-1.031-.124v3.078c0 .082-.04.156-.11.218a.291.291 0 01-.218.11h-.875c-.211 0-.313-.11-.313-.328v-11.75c0-.102.02-.18.063-.235.05-.062.148-.113.297-.156.457-.113.96-.207 1.515-.281a13.1 13.1 0 011.625-.11c1.282 0 2.223.387 2.828 1.157.602.761.907 1.78.907 3.062zm-1.515 0v-1.234c0-.894-.164-1.61-.484-2.14-.313-.54-.891-.813-1.735-.813-.343 0-.703.023-1.078.063-.375.03-.68.07-.906.109v6.765c.281.043.613.086 1 .125.383.032.71.047.984.047.844 0 1.422-.257 1.735-.78.32-.52.484-1.235.484-2.141zm0 0"
        />
        <path
          fill="currentColor"
          d="M2.828-.344a.393.393 0 01-.078.235.253.253 0 01-.219.109h-.89a.296.296 0 01-.22-.094.369.369 0 01-.093-.25v-12.172a.34.34 0 01.078-.234.318.318 0 01.235-.094h.89c.196 0 .297.11.297.328zm0 0"
        />
        <path
          fill="currentColor"
          d="M8.453-4.047c0 1.25-.324 2.262-.969 3.031C6.848-.254 5.898.125 4.641.125c-1.25 0-2.196-.379-2.829-1.14C1.176-1.786.86-2.798.86-4.048v-1.297c0-1.25.317-2.254.954-3.015.632-.77 1.578-1.157 2.828-1.157 1.257 0 2.207.387 2.843 1.157.645.761.97 1.765.97 3.015zm-1.5 0v-1.297c0-.875-.183-1.566-.547-2.078-.367-.52-.953-.781-1.765-.781-.793 0-1.371.262-1.735.781-.367.512-.547 1.203-.547 2.078v1.297c0 .867.18 1.559.547 2.078.364.512.942.766 1.735.766.8 0 1.382-.254 1.75-.766.375-.508.562-1.203.562-2.078zm0 0"
        />
        <path
          fill="currentColor"
          d="M5.39-8.469c0 .094-.023.164-.062.203-.031.043-.133.063-.297.063-.574 0-1.031.015-1.375.047a8.067 8.067 0 00-.984.125v7.687a.345.345 0 01-.094.235A.253.253 0 012.36 0h-.89a.296.296 0 01-.219-.094.369.369 0 01-.094-.25V-8.64c0-.164.102-.296.313-.39a5.093 5.093 0 011.625-.406c.594-.051 1.238-.079 1.937-.079a.61.61 0 01.266.047c.062.024.094.094.094.219zm0 0"
        />
        <path
          fill="currentColor"
          d="M2.89-11.234a.35.35 0 01-.109.25.318.318 0 01-.234.093h-.875a.369.369 0 01-.25-.093.369.369 0 01-.094-.25v-1.375c0-.082.031-.157.094-.22a.35.35 0 01.25-.108h.875c.094 0 .172.039.234.109.07.062.11.137.11.219zM2.86-.344a.35.35 0 01-.11.25.36.36 0 01-.234.094h-.829a.369.369 0 01-.25-.094.369.369 0 01-.093-.25v-8.703c0-.094.031-.172.093-.234a.35.35 0 01.25-.11h.829c.082 0 .16.04.234.11.07.062.11.14.11.234zm0 0"
        />
        <path
          fill="currentColor"
          d="M7.375-.344a.35.35 0 01-.11.25.318.318 0 01-.234.094H1.875c-.367 0-.633-.094-.797-.281a1.024 1.024 0 01-.234-.656v-.329c0-.238.023-.437.078-.593.05-.157.156-.329.312-.516l4.407-5.484c.039-.063.062-.114.062-.157 0-.07-.058-.109-.172-.109h-4.14a.369.369 0 01-.25-.094.369.369 0 01-.094-.25v-.578c0-.094.031-.172.094-.234a.35.35 0 01.25-.11h5.015c.352 0 .598.075.735.22.144.148.218.35.218.608v.422c0 .313-.132.625-.39.938l-4.5 5.61a.28.28 0 00-.078.187c0 .093.046.14.14.14h4.5c.094 0 .172.04.235.11.07.062.109.14.109.234zm0 0"
        />
        <path
          fill="currentColor"
          d="M8.406-4.594c0 .25-.11.375-.328.375H2.36v.235c0 1.023.196 1.742.594 2.156.395.418.988.625 1.781.625.5 0 1.032-.016 1.594-.047.57-.031 1.05-.078 1.438-.14h.093a.29.29 0 01.172.062c.063.031.094.094.094.187v.579a.494.494 0 01-.063.265c-.042.063-.136.106-.28.125a15.35 15.35 0 01-3.047.297 5.54 5.54 0 01-1.438-.188 3.411 3.411 0 01-1.25-.671c-.367-.313-.656-.727-.875-1.25-.211-.532-.313-1.196-.313-2v-1.532c0-1.218.32-2.187.97-2.906.655-.726 1.593-1.094 2.812-1.094 1.257 0 2.203.368 2.828 1.094.625.719.937 1.688.937 2.906zm-1.531-.765v-.157c0-.968-.188-1.656-.563-2.062-.367-.414-.921-.625-1.671-.625-.73 0-1.293.219-1.688.656-.398.43-.594 1.106-.594 2.031v.157zm0 0"
        />
        <path
          fill="currentColor"
          d="M9.188-.781c0 .136-.032.242-.094.312-.063.063-.211.121-.438.172A20.12 20.12 0 016.97.016c-.625.093-1.274.14-1.938.14C3.656.156 2.598-.227 1.86-1 1.117-1.77.75-2.844.75-4.219v-1.328c0-1.394.367-2.473 1.11-3.234.738-.758 1.796-1.14 3.171-1.14a15.298 15.298 0 013.625.438c.227.053.375.116.438.186.062.074.094.18.094.313zM6.438-2.22v-5.328A8.644 8.644 0 005.03-7.64c-.593 0-1 .2-1.218.594-.211.399-.313.899-.313 1.5v1.328c0 .606.102 1.106.313 1.5.218.399.624.594 1.218.594.29 0 .555-.008.797-.031.25-.02.453-.04.61-.063zm0 0"
        />
        <path
          fill="currentColor"
          d="M3.922-11.234a.35.35 0 01-.11.25.302.302 0 01-.218.093H1.359a.345.345 0 01-.234-.093.369.369 0 01-.094-.25v-1.594c0-.094.032-.172.094-.235a.33.33 0 01.234-.109h2.235c.082 0 .156.04.219.11.07.062.109.14.109.234zM3.859-.344a.35.35 0 01-.109.25.318.318 0 01-.234.094H1.437a.369.369 0 01-.25-.094.369.369 0 01-.093-.25v-9.094c0-.093.031-.171.093-.234a.35.35 0 01.25-.11h2.079c.093 0 .171.04.234.11.07.063.11.14.11.235zm0 0"
        />
      </>
    ),
  },
};

const theme = extendTheme({
  colors: {},
});

const customTheme = {
  ...theme,
  icons: {
    ...theme.icons,
    ...customIcons,
  },
};

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ChakraProvider>
        <ThemeProvider theme={customTheme}>
          <App />
        </ThemeProvider>
      </ChakraProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
