import api from "../../utils";

import {
  TOGGLE_LOADING,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_FAIL,
  LOGOUT_SUCCESS,
  CLEAR_CAMPAIGN_ERROR,
} from "../../constants/project";

// Action creators
export const toggleLoading = (loading) => ({
  type: TOGGLE_LOADING,
  payload: loading,
});

export const createCampaign = (campaignData) => async (dispatch) => {
  dispatch(toggleLoading(true));
  try {
    console.log(campaignData);
    const res = await api.post("/api/campaign", campaignData);
    if (res) {
      dispatch({ type: CREATE_CAMPAIGN_SUCCESS, payload: res.data });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: CREATE_CAMPAIGN_FAIL, payload: error.response.data });

    if (error.response.status === 401) {
      dispatch({ type: LOGOUT_SUCCESS });
    }
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const clearCampaignError = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_CAMPAIGN_ERROR });
  } catch (error) {
    console.log(error);
  }
};
