import {
  GET_CONVERSATIONS_SUCCESS,
  GET_CONVERSATIONS_FAIL,
  GET_RECORDING_SUCCESS,
  GET_RECORDING_FAIL,
  GET_ALL_PROSPECTS_SUCCESS,
  GET_ALL_PROSPECTS_FAIL,
  UPDATE_PROSPECT_SUCCESS,
  UPDATE_PROSPECT_FAIL,
  TOGGLE_LOADING,
} from "../../constants/project";

const initialState = {
  loading: false,
  conversations: [],
  recordings: [],
  prospects: [],
  isError: false,
  errorMessage: "",
  message: "",
  loadingRecording: {},
};

const crmReducer = (state = initialState, action) => {
  const { type, payload } = action;
  const { loadingRecording } = state;
  switch (type) {
    case TOGGLE_LOADING: {
      // alert(payload.recordingSid);
      if (payload.recordingSid)
        loadingRecording[payload.recordingSid] = payload.loading;
      return {
        ...state,
        ...payload,
        loadingRecording: {
          ...loadingRecording,
        },
      };
    }
    case GET_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
        conversations: payload.conversations,
        recordings: payload.recordings,
        errorMessage: "",
        message: "",
        isError: false,
      };

    case GET_CONVERSATIONS_FAIL:
      return {
        ...state,
        ...payload,
        loading: false,
        errorMessage: payload.message,
        message: "",
        conversations: [],
        recordings: [],
        isError: true,
      };
    case GET_RECORDING_SUCCESS:
      // alert(payload.recordingSid);
      if (payload.recordingSid)
        loadingRecording[payload.recordingSid] = payload.loading;
      loadingRecording[payload.recordingSid] = false;
      return {
        ...state,
        ...payload,
        loading: false,
        message: "",
        recordings: state.recordings.map((recording) => {
          if (payload.recordingSid === recording.recordingSid) {
            return { ...recording, recordingUrl: payload.url };
          }
          return recording;
        }),
        errorMessage: "",
        isError: false,
        loadingRecording: {
          ...loadingRecording,
        },
      };

    case GET_RECORDING_FAIL:
      // alert(payload.recordingSid);
      if (payload.recordingSid)
        loadingRecording[payload.recordingSid] = payload.loading;
      loadingRecording[payload.recordingSid] = false;
      return {
        ...state,
        ...payload,
        loading: false,
        errorMessage: payload.data,
        message: "",
        recordings: [],
        isError: true,
        loadingRecording: {
          ...loadingRecording,
        },
      };
    case GET_ALL_PROSPECTS_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
        prospects: payload.prospects,
        errorMessage: "",
        message: "",
        isError: false,
      };

    case GET_ALL_PROSPECTS_FAIL:
      return {
        ...state,
        ...payload,
        loading: false,
        prospects: [],
        errorMessage: "",
        isError: true,
      };
    case UPDATE_PROSPECT_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
        errorMessage: "",
        isError: false,
        message: payload.message,
      };
    case UPDATE_PROSPECT_FAIL:
      return {
        ...state,
        ...payload,
        loading: false,
        errorMessage: payload.message,
        message: "",
        isError: true,
      };
    default:
      return state;
  }
};

export default crmReducer;
