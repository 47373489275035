import * as Yup from "yup";

export const CampaignSchema = Yup.object({
  campaignType: Yup.string().required("Campaign Type Required"),
  users: Yup.array()
    .min(1, "At least one user must be checked")
    .required("Users Required"),
  startDate: Yup.date().required("Start Date Required"),
  endDate: Yup.date().required("End Date Required"),
  companyId: Yup.string().required("Company Id Required"),
});
