import {
  TOGGLE_LOADING,
  UPLOAD_SUCCESS,
  UPLOAD_FAIL,
  UPLOAD_FINISHED,
  GET_PARSED_USERS_SUCCESS,
  GET_PARSED_USERS_FAIL,
  SAVE_AI_INSTRUCTIONS_SUCCESS,
  SAVE_AI_INSTRUCTIONS_FAIL,
  GET_AI_INSTRUCTIONS_FAIL,
  GET_AI_INSTRUCTIONS_SUCCESS,
  CLEAR_AI_INSTRUCTIONS,
} from "../../constants/project";

// Initial state

const initialState = {
  loading: false,
  fileSent: false,
  parsedUsersFromFiles: [],
  isError: false,
  errorMessage: "",
  saveAiInstructions: false,
  aiData: null,
};

// Reducer function
const dataReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_LOADING:
      return {
        ...state,
        loading: payload,
        isError: false,
        fileSent: false,
        errorMessage: "",
        // aiData: payload ? null : state.aiData,
      };
    case UPLOAD_SUCCESS:
      return {
        ...state,
        fileSent: true,
        loading: false,
        errorMessage: "",
        isError: false,
      };
    case UPLOAD_FAIL:
      return {
        ...state,
        fileSent: false,
        loading: false,
        errorMessage: payload.error,
        isError: true,
      };
    case UPLOAD_FINISHED:
      return {
        ...state,
        fileSent: false,
        loading: false,
        errorMessage: "",
        isError: false,
      };
    case GET_PARSED_USERS_SUCCESS:
      return {
        ...state,
        parsedUsersFromFiles: payload.users,
        loading: false,
        errorMessage: "",
        isError: false,
      };
    case GET_PARSED_USERS_FAIL:
      return {
        ...state,
        parsedUsersFromFiles: [],
        loading: false,
        errorMessage: payload.message,
        isError: true,
      };
    case SAVE_AI_INSTRUCTIONS_SUCCESS:
      return {
        ...state,
        parsedUsersFromFiles: payload.users,
        loading: false,
        errorMessage: "",
        isError: false,
        saveAiInstructions: { value: true, date: new Date() },
      };
    case SAVE_AI_INSTRUCTIONS_FAIL:
      return {
        ...state,
        parsedUsersFromFiles: [],
        loading: false,
        errorMessage: payload.message,
        isError: true,
        saveAiInstructions: { value: false, date: new Date() },
      };
    case GET_AI_INSTRUCTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: "",
        isError: false,
        aiData: payload.aiData,
      };
    case CLEAR_AI_INSTRUCTIONS:
      return {
        ...state,
        aiData: null,
      };
    case GET_AI_INSTRUCTIONS_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: payload.message,
        isError: true,
        aiData: null,
      };
    default:
      return state;
  }
};

export default dataReducer;
