import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  Button,
  ModalFooter,
  Select,
} from "@chakra-ui/react";

const ViewAdminModal = ({ isOpen, onClose, admin }) => {
  const dispatch = useDispatch();
  const [adminData, setAdminData] = useState(admin);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent backgroundColor={"white"}>
        <ModalHeader color={"yellow.400"}>View Admin</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl>
            <FormLabel color={"yellow.400"}>Email</FormLabel>
            <Input
              placeholder="Email"
              _placeholder={{ color: "yellow.400" }}
              value={adminData?.email || ""}
              isReadOnly
            />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel color={"yellow.400"}>Name</FormLabel>
            <Input
              placeholder="Name"
              _placeholder={{ color: "yellow.400" }}
              value={adminData?.name || ""}
              isReadOnly
            />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel color={"yellow.400"}>Password</FormLabel>
            <Input
              type="password"
              placeholder="Password"
              _placeholder={{ color: "yellow.400" }}
              value={adminData?.password || ""}
              isReadOnly
            />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel color={"yellow.400"}>Role</FormLabel>
            <Select
              color="white"
              bg="darkorange"
              colorScheme="white"
              value={adminData?.role || ""}
              isReadOnly
            >
              <option
                style={{ backgroundColor: "darkorange", color: "white" }}
                color="darkorange"
                value="user"
              >
                User
              </option>
              <option
                style={{ backgroundColor: "darkorange", color: "white" }}
                color="darkorange"
                value="admin"
              >
                Admin
              </option>
              <option
                style={{ backgroundColor: "darkorange", color: "white" }}
                color="darkorange"
                value="superadmin"
              >
                Superadmin
              </option>
            </Select>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button borderRadius={0} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ViewAdminModal;
