import api from "../../utils";
import {
  TOGGLE_LOADING,
  GET_CONVERSATIONS_SUCCESS,
  GET_CONVERSATIONS_FAIL,
  GET_ALL_PROSPECTS_SUCCESS,
  GET_ALL_PROSPECTS_FAIL,
  GET_RECORDING_SUCCESS,
  GET_RECORDING_FAIL,
  LOGOUT_SUCCESS,
  UPDATE_PROSPECT_SUCCESS,
  UPDATE_PROSPECT_FAIL,
} from "../../constants/project";

// Action creators
export const toggleLoading = (recordingSid, loading) => ({
  type: TOGGLE_LOADING,
  payload: { recordingSid, loading },
});

export const setProspectsStatus = (crmData) => async (dispatch) => {
  dispatch(toggleLoading(true));
  try {
    const res = await api.put("/api/campaign/setProspectsStatus", crmData);
    if (res) {
      dispatch({ type: UPDATE_PROSPECT_SUCCESS, payload: res.data });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: UPDATE_PROSPECT_FAIL,
      payload: error.response.data,
    });

    if (error.response.status === 401) {
      dispatch({ type: LOGOUT_SUCCESS });
    }
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const getConversationDetails =
  (startDate, endDate, phoneNumber, email) => async (dispatch) => {
    dispatch(toggleLoading(true));
    try {
      const res = await api.get("/api/conversation", {
        params: { startDate, endDate, phoneNumber, email },
      });

      console.log(res.data);
      if (res) {
        dispatch({ type: GET_CONVERSATIONS_SUCCESS, payload: res.data });
      }
    } catch (error) {
      dispatch({
        type: GET_CONVERSATIONS_FAIL,
        payload: error.response.data,
      });
      if (error.response.status === 401) {
        dispatch({ type: LOGOUT_SUCCESS });
      }
    } finally {
      dispatch(toggleLoading(false));
    }
  };

export const getCampaignsProspects =
  (startDate, endDate) => async (dispatch) => {
    dispatch(toggleLoading(true));
    try {
      const res = await api.get("/api/campaign/getAllProspects", {
        params: {
          startDate,
          endDate,
        },
      });

      console.log(res.data);
      if (res) {
        dispatch({ type: GET_ALL_PROSPECTS_SUCCESS, payload: res.data });
      }
    } catch (error) {
      dispatch({
        type: GET_ALL_PROSPECTS_FAIL,
        payload: error.response.data,
      });
      if (error.response.status === 401) {
        dispatch({ type: LOGOUT_SUCCESS });
      }
    } finally {
      dispatch(toggleLoading(false));
    }
  };

export const getRecording = (recordingSid) => async (dispatch) => {
  dispatch(toggleLoading(recordingSid, true));
  try {
    // Make sure the responseType is set to 'blob'
    const res = await api.get(`/api/conversation/recording/${recordingSid}`, {
      responseType: "blob", // Ensure the response is treated as binary data
    });

    // Create a URL for the blob
    const url = URL.createObjectURL(res.data);

    // Dispatch success action with the URL and recording SID
    if (url) {
      dispatch({
        type: GET_RECORDING_SUCCESS,
        payload: { url, recordingSid },
      });
    }
  } catch (error) {
    // Handle errors and dispatch failure action
    dispatch({
      type: GET_RECORDING_FAIL,
      payload: {
        data: error.response ? error.response.data : error.message,
        recordingSid,
      },
    });

    if (error.response.status === 401) {
      dispatch({ type: LOGOUT_SUCCESS });
    }
  } finally {
    dispatch(toggleLoading(recordingSid, false));
  }
};
