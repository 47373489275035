// src/redux/reducers/adminReducer.js
import {
  GET_ADMINS_SUCCESS,
  GET_ADMINS_FAIL,
  CREATE_ADMIN_SUCCESS,
  UPDATE_ADMIN_SUCCESS,
  UPDATE_ADMIN_FAIL,
  DELETE_ADMIN_FAIL,
  TOGGLE_LOADING,
  DELETE_ADMIN_SUCCESS,
  SEARCH_ADMINS_SUCCESS,
  SEARCH_ADMINS_FAIL,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_FAIL,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAIL,
} from "constants/project";
import _ from "lodash";

const initialState = {
  admins: [],
  loading: false,
  error: null,
  isError: false,
  emailSent: false,
  message: "",
  totalPages: 1,
  company: null,
  companies: [],
};

const adminReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case TOGGLE_LOADING:
      return {
        ...state,
        ...payload,
        loading: payload,
        isError: false,
        error: null,
        message: payload ? "" : state.message,
      };
    case CREATE_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        error: null,
        message: payload.message,
      };

    case UPDATE_ADMIN_SUCCESS:
      return {
        ...state,
        admins: state.admins.map((admin) =>
          admin._id === action.payload?.admin?._id
            ? action.payload.admin
            : admin
        ),
      };
    case UPDATE_ADMIN_FAIL:
      return {
        ...state,
        loading: false,
        isError: true,
        error: payload.message,
        message: "",
      };
    case DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        error: null,
        message: "",
        admins: state.admins.filter(
          (admin) => admin._id !== action.payload?.admin?._id
        ),
      };
    case DELETE_ADMIN_FAIL:
      return {
        ...state,
        loading: false,
        isError: true,
        error: payload.message,
        message: "",
      };
    case GET_ADMINS_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
        admins: payload.admins,
        totalPages: payload.totalPages,
        errorMessage: "",
        isError: false,
      };

    case GET_ADMINS_FAIL:
      return {
        ...state,
        ...payload,
        loading: false,
        totalPages: 1,
        admins: [],
        message: "",
        error: payload.message,
        isError: true,
      };
    case SEARCH_ADMINS_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
        admins: payload.admins,
        totalPages: payload.totalPages,
        error: "",
        isError: false,
      };
    case SEARCH_ADMINS_FAIL:
      return {
        ...state,
        ...payload,
        loading: false,
        totalPages: 1,
        admins: [],
        message: "",
        error: payload.message,
        isError: true,
      };
    case CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
        error: "",
        isError: false,
        company: payload.company,
      };
    case CREATE_COMPANY_FAIL:
      return {
        ...state,
        ...payload,
        loading: false,
        error: payload.message,
        message: payload.message,
        isError: true,
        company: null,
      };
    case GET_COMPANIES_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
        error: "",
        isError: false,
        companies: payload.companies,
      };
    case GET_COMPANIES_FAIL:
      return {
        ...state,
        ...payload,
        loading: false,
        error: payload.message,
        message: payload.message,
        isError: true,
        companies: [],
      };
    default:
      return state;
  }
};

export default adminReducer;
