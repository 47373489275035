import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { login, register } from "../redux/actions/authActions";
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Link,
  Avatar,
  FormControl,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { FaUserAlt, FaLock, FaHandPeace } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { SiApple } from "react-icons/si";
import { useFormik } from "formik";
import { SignupSchema } from "../validationSchemas/SignupSchema";
import { SigninSchema } from "../validationSchemas/SigninSchema";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { useToast } from "@chakra-ui/react";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

const Login = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleShowClick = () => setShowPassword(!showPassword);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const toast = useToast();

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      dispatch(login({ email: values.email, password: values.password }));
    },
    validationSchema: SigninSchema,
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const verify = queryParams.get("verify");

    if (verify && verify === "true") {
      toast({
        position: "top",
        title: "Account verified",
        description: "Congratulations! Your account was verified",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else if (verify && verify === "false") {
      toast({
        position: "top",
        title: "Failure",
        description: "We're sorry, we could not verify your account",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [location.search, toast]);

  useEffect(() => {
    if (props.isError)
      toast({
        position: "top",
        title: "Failure",
        description: props.error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
  }, [props.loading, props.isError, props.error]);

  useEffect(() => {
    if (props.isAuthenticated) {
      navigate("/dashboard");
    }
  }, [props.isAuthenticated]);

  const googleLogin = () => {
    window.open(`${process.env.REACT_APP_SERVER_URI}api/auth/google`, "_self");
  };

  return (
    <div style={{ color: "black" }}>
      <Flex
        flexDirection="column"
        width="100wh"
        height="100vh"
        backgroundColor="white"
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          flexDir="column"
          mb="2"
          justifyContent="center"
          alignItems="center"
        >
          {/* <FaHandPeace color="darkorange" size={40} /> */}
          <img src="orangeLogo.png" width={"200px"} alt="Explorize AI"></img>
          <Heading color="#EBC94A">Welcome</Heading>
          <Box minW={{ base: "90%", md: "468px" }}>
            <form onSubmit={formik.handleSubmit}>
              <Stack
                spacing={4}
                p="1rem"
                backgroundColor="white"
                boxShadow="md"
              >
                <FormControl>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<CFaUserAlt color="gray.500" />}
                    />
                    <Input
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      type="email"
                      placeholder="email address"
                      color={"black"}
                      _placeholder={{ color: "gray.500" }}
                    />
                  </InputGroup>
                </FormControl>
                <div>
                  {formik.errors.email ? (
                    <p style={{ color: "red" }}>{formik.errors.email}</p>
                  ) : null}
                </div>
                <FormControl>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<CFaLock color="gray.500" />}
                    />
                    <Input
                      name="password"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      color="black"
                      _placeholder={{ color: "gray.500" }}
                    />

                    <InputRightElement width="4.5rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        background={"none"}
                        _hover={{ color: "yellow.500" }}
                        color={"yellow.400"}
                        _active={{ color: "yellow.700" }}
                        onClick={handleShowClick}
                      >
                        {showPassword ? "Hide" : "Show"}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <div>
                  {formik.errors.password ? (
                    <p style={{ color: "red" }}>{formik.errors.password}</p>
                  ) : null}
                </div>
                <FormControl></FormControl>
                <Button
                  borderRadius={0}
                  type="submit"
                  variant="solid"
                  colorScheme="yellow"
                  width="full"
                >
                  Login
                </Button>
              </Stack>
            </form>
            <Stack spacing={4} p="1rem" backgroundColor="white" boxShadow="md">
              <Button
                borderRadius={0}
                type="submit"
                variant="solid"
                colorScheme="yellow"
                width="full"
                onClick={googleLogin}
              >
                <FcGoogle size={25} style={{ marginRight: "8px" }} />
                Sign in with Google
              </Button>
              {/* <Button
                borderRadius={0}
                type="submit"
                variant="solid"
                colorScheme="yellow"
                width="full"
              >
                <SiApple size={25} style={{ marginRight: "8px" }} />
                Sign in with Apple
              </Button> */}
            </Stack>
          </Box>
          <Flex
            justifyContent={"space-evenly"}
            width={"-webkit-fill-available"}
          >
            <Flex>
              {" "}
              <Link color="darkorange" href="/forgotpassword">
                Forgot Password
              </Link>
            </Flex>
            <Flex>
              <Text color={"black"} mr={2}>
                New to us?
              </Text>
              <Link color="darkorange" href="/signup">
                Sign Up
              </Link>
            </Flex>
          </Flex>
        </Stack>
      </Flex>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    loading: state.auth.loading,
    isError: state.auth.isError,
    isAuthenticated: state.auth.isAuthenticated,
    error: state.auth.error,
  };
}
export default connect(mapStateToProps)(Login);
