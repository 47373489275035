export const GET_PROJECTS_REQUEST = "GET_PROJECTS_REQUEST";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_FAILURE = "GET_PROJECTS_FAILURE";

export const CREATE_PROJECT_REQUEST = "CREATE_PROJECT_REQUEST";
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";
export const CREATE_PROJECT_FAILURE = "CREATE_PROJECT_FAILURE";

export const GET_PROJECT_REQUEST = "GET_PROJECT_REQUEST";
export const GET_PROJECT_SUCCESS = "GET_PROJECT_SUCCESS";
export const GET_PROJECT_FAILURE = "GET_PROJECT_FAILURE";

export const UPDATE_PROJECT_REQUEST = "UPDATE_PROJECT_REQUEST";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UPDATE_PROJECT_FAILURE = "UPDATE_PROJECT_FAILURE";

export const DELETE_PROJECT_REQUEST = "DELETE_PROJECT_REQUEST";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const DELETE_PROJECT_FAILURE = "DELETE_PROJECT_FAILURE";

export const SEARCH_PROJECTS_SUCCESS = "SEARCH_PROJECTS_SUCCESS";
export const SEARCH_PROJECTS_FAIL = "SEARCH_PROJECTS_FAIL";

export const ADD_ISSUEORCOMMENT_REQUEST = "ADD_ISSUEORCOMMENT_REQUEST";
export const ADD_ISSUEORCOMMENT_SUCCESS = "ADD_ISSUEORCOMMENT_SUCCESS";
export const ADD_ISSUEORCOMMENT_FAILURE = "ADD_ISSUEORCOMMENT_FAILURE";

export const UPDATE_ISSUEORCOMMENT_REQUEST = "UPDATE_ISSUEORCOMMENT_REQUEST";
export const UPDATE_ISSUEORCOMMENT_SUCCESS = "UPDATE_ISSUEORCOMMENT_SUCCESS";
export const UPDATE_ISSUEORCOMMENT_FAILURE = "UPDATE_ISSUEORCOMMENT_FAILURE";

export const DELETE_ISSUEORCOMMENT_REQUEST = "DELETE_ISSUEORCOMMENT_REQUEST";
export const DELETE_ISSUEORCOMMENT_SUCCESS = "DELETE_ISSUEORCOMMENT_SUCCESS";
export const DELETE_ISSUEORCOMMENT_FAILURE = "DELETE_ISSUEORCOMMENT_FAILURE";

export const DatabaseFileType = {
  prospectsFile: "prospectsFile",
  pdfOfferFile: "pdfOfferFile",
};

// register and login
export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
export const RESEND_EMAIL_SUCCESS = "RESEND_EMAIL_SUCCESS";
export const RESEND_EMAIL_FAIL = "RESEND_EMAIL_FAIL";
export const RESET_EMAIL_SENT = "RESET_EMAIL_SENT";

export const SENT_RESET_PASSWORD_SUCCESS = "SENT_RESET_PASSWORD_SUCCESS";
export const SENT_RESET_PASSWORD_FAIL = "SENT_RESET_PASSWORD_FAIL";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

//campaign consts
export const CREATE_CAMPAIGN_SUCCESS = "CREATE_CAMPAIGN_SUCCESS";
export const CREATE_CAMPAIGN_FAIL = "CREATE_CAMPAIGN_FAIL";
export const UPDATE_PROSPECT_SUCCESS = "UPDATE_PROSPECT_SUCCESS";
export const UPDATE_PROSPECT_FAIL = "UPDATE_PROSPECT_FAIL";
export const CLEAR_CAMPAIGN_ERROR = "CLEAR_CAMPAIGN_ERROR";

// crm consts
export const GET_CONVERSATIONS_SUCCESS = "GET_CONVERSATIONS_SUCCESS";
export const GET_CONVERSATIONS_FAIL = "GET_CONVERSATIONS_FAIL";

export const GET_ALL_PROSPECTS_SUCCESS = "GET_ALL_PROSPECTS_SUCCESS";
export const GET_ALL_PROSPECTS_FAIL = "GET_ALL_PROSPECTS_FAIL";

export const GET_RECORDING_SUCCESS = "GET_RECORDING_SUCCESS";
export const GET_RECORDING_FAIL = "GET_RECORDING_FAIL";

//data consts

export const UPLOAD_SUCCESS = "UPLOAD_SUCCESS";
export const UPLOAD_FAIL = "UPLOAD_FAIL";
export const UPLOAD_FINISHED = "UPLOAD_FINISHED";

export const SAVE_AI_INSTRUCTIONS_SUCCESS = "SAVE_AI_INSTRUCTIONS_SUCCESS";
export const SAVE_AI_INSTRUCTIONS_FAIL = "SAVE_AI_INSTRUCTIONS_FAIL";
export const GET_AI_INSTRUCTIONS_SUCCESS = "GET_AI_INSTRUCTIONS_SUCCESS";
export const GET_AI_INSTRUCTIONS_FAIL = "GET_AI_INSTRUCTIONS_FAIL";
export const CLEAR_AI_INSTRUCTIONS = "CLEAR_AI_INSTRUCTIONS";

export const GET_PARSED_USERS_SUCCESS = "GET_PARSED_USERS_SUCCESS";
export const GET_PARSED_USERS_FAIL = "GET_PARSED_USERS_FAIL";
export const ADD_ADMIN = "ADD_ADMIN";
export const EDIT_ADMIN = "EDIT_ADMIN";
export const DELETE_ADMIN = "DELETE_ADMIN";
export const GET_ADMINS_SUCCESS = "GET_ADMINS_SUCCESS";
export const GET_ADMINS_FAIL = "GET_ADMINS_FAIL";
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS";
export const DELETE_ADMIN_FAIL = "DELETE_ADMIN_FAIL";
export const CREATE_ADMIN_SUCCESS = "CREATE_ADMIN_SUCCESS";
export const CREATE_ADMIN_FAIL = "CREATE_ADMIN_FAIL";
export const UPDATE_ADMIN_SUCCESS = "UPDATE_ADMIN_SUCCESS";
export const UPDATE_ADMIN_FAIL = "UPDATE_ADMIN_FAIL";
export const SEARCH_ADMINS_SUCCESS = "SEARCH_ADMINS_SUCCESS";
export const SEARCH_ADMINS_FAIL = "SEARCH_ADMINS_FAIL";

export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS";
export const CREATE_COMPANY_FAIL = "CREATE_COMPANY_FAIL";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_FAIL = "GET_COMPANIES_FAIL";
