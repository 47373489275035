import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import Navbar from "./Navbar";
import { useFormik } from "formik";
import {
  Flex,
  Button,
  Input,
  Box,
  Select,
  Skeleton,
  FormErrorMessage,
  FormLabel,
  FormControl,
} from "@chakra-ui/react";
import AdminCard from "./common/admin/AdminCard";
import CreateModal from "./common/admin/create";
import UpdateAdminModal from "./common/admin/edit";
import ViewAdminModal from "./common/admin/view";
import { useToast } from "@chakra-ui/react";
import {
  createCompany,
  deleteAdmin,
  getAdmins,
  searchAdmins,
} from "../redux/actions/adminActions";
import { getUserById } from "../redux/actions/authActions";
import { useSearchParams, useLocation } from "react-router-dom";
import { CompanySchema } from "../validationSchemas/CompanySchema";
import { diffSeconds } from "helpers";

const AdminManagement = (props) => {
  const [createable, setCreateable] = useState(false);
  const [updateable, setUpdateable] = useState(false);
  const [showable, setShowable] = useState(false);
  const [selected, setSelected] = useState(null);
  const [emailQuery, setEmailQuery] = useState("");
  const [nameQuery, setNameQuery] = useState("");
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [selectedAdmin, setSelectedAdmin] = useState();

  const dispatch = useDispatch();
  const toast = useToast();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const userId = searchParams.get("userId");
  const token = searchParams.get("token");
  const formik = useFormik({
    initialValues: {
      companyName: "",
      shortCompanyName: "",
      companyRegistrationId: "",
    },
    onSubmit: (values) => {
      dispatch(createCompany(values));
    },
    validationSchema: CompanySchema,
  });

  const handleClick = (type, open, id, selectedAdmin) => {
    setSelected(id);
    setSelectedAdmin(selectedAdmin);
    switch (type) {
      case "show":
        setShowable(open);
        break;
      case "edit":
        setUpdateable(open);
        break;
      case "delete":
        dispatch(deleteAdmin(id));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (userId && token) dispatch(getUserById({ userId, token }));
  }, [dispatch, userId, token]);

  useEffect(() => {
    dispatch(getAdmins(props.user?._id, currentPage, limit));
  }, [dispatch, props.user, currentPage, limit]);

  useEffect(() => {
    if (props.company && diffSeconds(props.company?.createdAt) < 3) {
      toast({
        position: "top",
        title: "Success",
        description: "The company was created successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else if (props.isError) {
      toast({
        position: "top",
        title: "Failure",
        description: props.error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [props.company, props.error, props.isError]);

  useEffect(() => {
    if (emailQuery.length >= 3 || nameQuery.length >= 3 || sortOrder !== "") {
      dispatch(
        searchAdmins({
          email: emailQuery,
          name: nameQuery,
          adminId: props.user?._id,
          sortField,
          sortOrder,
          currentPage: currentPage,
          limit,
        })
      );
    } else if (emailQuery.length === 0 || nameQuery.length === 0) {
      dispatch(getAdmins(props.user?._id, currentPage, limit));
    }
  }, [
    emailQuery,
    nameQuery,
    sortField,
    sortOrder,
    currentPage,
    limit,
    dispatch,
  ]);

  useEffect(() => {
    // Update total pages based on fetched data (assuming it comes from props)
    if (props.totalPages) {
      setTotalPages(props.totalPages);
    }
  }, [props.totalPages]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    // <Skeleton isLoaded={!props.loading}>
    <div>
      <Navbar />
      <Flex
        flexDirection="column"
        width="100wh"
        height="100vh"
        backgroundColor="white"
        justifyContent="top"
        alignItems="center"
      >
        {props.user?.role === "superadmin" && (
          <form onSubmit={formik.handleSubmit}>
            <Flex
              flexDirection="column"
              backgroundColor="white"
              justifyContent="top"
              alignItems="center"
            >
              <FormControl
                isInvalid={
                  formik.errors.companyName && formik.touched.companyName
                }
              >
                <FormLabel color={"yellow.400"}>Company Name</FormLabel>
                <Input
                  placeholder="Company Name"
                  color="black"
                  name="companyName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.companyName}
                />
                <FormErrorMessage>{formik.errors.companyName}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={
                  formik.errors.shortCompanyName &&
                  formik.touched.shortCompanyName
                }
              >
                <FormLabel color={"yellow.400"}>Short Company Name</FormLabel>
                <Input
                  placeholder="Short Company Name"
                  color="black"
                  name="shortCompanyName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.shortCompanyName}
                />
                <FormErrorMessage>
                  {formik.errors.shortCompanyName}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                mt={4}
                isInvalid={
                  formik.errors.companyRegistrationId &&
                  formik.touched.companyRegistrationId
                }
              >
                <FormLabel color={"yellow.400"}>
                  Company Registration Id
                </FormLabel>
                <Input
                  placeholder="Company Registration Id"
                  color="black"
                  name="companyRegistrationId"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.companyRegistrationId}
                />
                <FormErrorMessage>
                  {formik.errors.companyRegistrationId}
                </FormErrorMessage>
              </FormControl>

              <Button
                alignSelf={"center"}
                justifyContent={"center"}
                colorScheme="yellow"
                borderRadius={0}
                mr={3}
                mt={5}
                type="submit"
              >
                Create Company
              </Button>
            </Flex>
          </form>
        )}

        {props.user?.role === "superadmin" && (
          <Button
            mt={5}
            mb={5}
            variant="solid"
            colorScheme="yellow"
            minHeight={"40px"}
            onClick={() => setCreateable(true)}
          >
            Create Admin
          </Button>
        )}

        <Box
          mt={5}
          mb={5}
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={3}
        >
          <Input
            color={"black"}
            placeholder="Search by email"
            value={emailQuery}
            onChange={(e) => setEmailQuery(e.target.value)}
            width="300px"
          />
          <Input
            color={"black"}
            placeholder="Search by name"
            value={nameQuery}
            onChange={(e) => setNameQuery(e.target.value)}
            width="300px"
          />
        </Box>

        <Box
          mb={5}
          display="flex"
          flexDirection="row"
          gap={3}
          minWidth={"280px"}
        >
          <Select
            onChange={(e) => setSortField(e.target.value)}
            style={{ backgroundColor: "darkorange", color: "white" }}
            color="darkorange"
            colorScheme={"white"}
          >
            <option selected hidden disabled value="">
              Sort by
            </option>

            <option
              style={{ backgroundColor: "darkorange", color: "white" }}
              color="darkorange"
              value="name"
            >
              Name
            </option>
            <option
              style={{ backgroundColor: "darkorange", color: "white" }}
              color="darkorange"
              value="email"
            >
              Email
            </option>
          </Select>
          <Select
            onChange={(e) => setSortOrder(e.target.value)}
            style={{ backgroundColor: "darkorange", color: "white" }}
            color="darkorange"
            colorScheme={"white"}
            minWidth={"100px"}
            value={sortOrder}
          >
            <option selected hidden disabled value="">
              Sort Order
            </option>

            <option
              style={{ backgroundColor: "darkorange", color: "white" }}
              color="darkorange"
              value="asc"
            >
              Asc
            </option>
            <option
              style={{ backgroundColor: "darkorange", color: "white" }}
              color="darkorange"
              value="desc"
            >
              Desc
            </option>
          </Select>
          <Select
            onChange={(e) => setLimit(Number(e.target.value))}
            style={{ backgroundColor: "darkorange", color: "white" }}
            color="darkorange"
            colorScheme={"white"}
            minWidth={"100px"}
            value={limit}
          >
            <option
              style={{ backgroundColor: "darkorange", color: "white" }}
              color="darkorange"
              value={2}
            >
              2
            </option>
            <option
              style={{ backgroundColor: "darkorange", color: "white" }}
              color="darkorange"
              value={7}
            >
              7
            </option>
            <option
              style={{ backgroundColor: "darkorange", color: "white" }}
              color="darkorange"
              value={10}
            >
              10
            </option>
          </Select>
        </Box>

        <Flex
          flexDirection="row"
          backgroundColor="white"
          justifyContent="center"
          alignItems="center"
          flexWrap={"wrap"}
          gap={"10"}
        >
          {props.admins &&
            props.admins.map((admin, key) => (
              <>
                <AdminCard
                  key={key}
                  admin={admin}
                  onClick={(type, open, id) =>
                    handleClick(type, open, id, admin)
                  }
                />
              </>
            ))}
        </Flex>

        <UpdateAdminModal
          isOpen={updateable}
          onClose={() => setUpdateable(false)}
          adminId={selected}
          admin={selectedAdmin}
        />
        <ViewAdminModal
          isOpen={showable}
          onClose={() => setShowable(false)}
          adminId={selected}
          admin={selectedAdmin}
        />
        <Flex mt={5} mb={5} justifyContent="center" alignItems="center" gap={3}>
          <Button
            isDisabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
            colorScheme="yellow"
          >
            Previous
          </Button>
          <Box color="orange">{`Page ${currentPage} of ${totalPages}`}</Box>
          <Button
            isDisabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
            colorScheme="yellow"
          >
            Next
          </Button>
        </Flex>
      </Flex>
      <CreateModal isOpen={createable} onClose={() => setCreateable(false)} />
    </div>
    // </Skeleton>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    admins: state.admin.admins,
    loading: state.admin.loading,
    isAuthenticated: state.auth.isAuthenticated,
    totalPages: state.admin.totalPages,
    isError: state.admin.isError,
    error: state.admin.error,
    message: state.admin.message,
    company: state.admin.company, // Assuming the total pages are stored in the state
  };
}

export default connect(mapStateToProps)(AdminManagement);
