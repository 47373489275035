import api from "../../utils";
import {
  TOGGLE_LOADING,
  GET_ADMINS_SUCCESS,
  GET_ADMINS_FAIL,
  CREATE_ADMIN_FAIL,
  CREATE_ADMIN_SUCCESS,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  UPDATE_ADMIN_SUCCESS,
  UPDATE_ADMIN_FAIL,
  DELETE_ADMIN_FAIL,
  DELETE_ADMIN_SUCCESS,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_FAIL,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_FAIL,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAIL,
} from "../../constants/project";

// Action creators
export const toggleLoading = (loading) => ({
  type: TOGGLE_LOADING,
  payload: loading,
});

export const getAdmins = (adminId, currentPage, limit) => async (dispatch) => {
  dispatch(toggleLoading(true));
  try {
    const res = await api.get("/api/admin/getAdmins", {
      params: {
        adminId: adminId,
        currentPage: currentPage,
        limit: limit,
      },
    });

    console.log(res.data);
    if (res) {
      dispatch({ type: GET_ADMINS_SUCCESS, payload: res.data });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_ADMINS_FAIL,
      payload: error.response.data,
    });
    if (error.response.status === 401) {
      dispatch({ type: LOGOUT_SUCCESS });
    }
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const getCompanies = (companyData) => async (dispatch) => {
  dispatch(toggleLoading(true));
  try {
    const res = await api.get("/api/admin/getCompanies", {
      params: {
        companyRegistrationId: companyData?.companyRegistrationId,
      },
    });

    console.log(res.data);
    if (res) {
      dispatch({ type: GET_COMPANIES_SUCCESS, payload: res.data });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_COMPANIES_FAIL,
      payload: error.response.data,
    });
    if (error.response.status === 401) {
      dispatch({ type: LOGOUT_SUCCESS });
    }
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const deleteAdmin = (adminId) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/api/admin/${adminId}`);
    dispatch({
      type: DELETE_ADMIN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_ADMIN_FAIL,
      payload: error.response,
    });
    if (error.response.status === 401) {
      dispatch({ type: LOGOUT_SUCCESS });
    }
  }
};

export const updateAdmin = (adminId, adminData) => async (dispatch) => {
  dispatch(toggleLoading(true));
  try {
    const res = await api.put(`/api/admin/${adminId}`, adminData);
    if (res) dispatch({ type: UPDATE_ADMIN_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({ type: UPDATE_ADMIN_FAIL, payload: error.response.data });
    if (error.response.status === 401) {
      dispatch({ type: LOGOUT_SUCCESS });
    }
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const createAdmin = (userData) => async (dispatch) => {
  dispatch(toggleLoading(true));
  try {
    const res = await api.post("/api/admin", userData);
    if (res) dispatch({ type: CREATE_ADMIN_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({ type: CREATE_ADMIN_FAIL, payload: error.response.data });
    if (error.response.status === 401) {
      dispatch({ type: LOGOUT_SUCCESS });
    }
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const createCompany = (companyData) => async (dispatch) => {
  dispatch(toggleLoading(true));
  try {
    const res = await api.post("/api/admin/createCompany", companyData);
    if (res) dispatch({ type: CREATE_COMPANY_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({ type: CREATE_COMPANY_FAIL, payload: error.response.data });
    if (error.response.status === 401) {
      dispatch({ type: LOGOUT_SUCCESS });
    }
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const searchAdmins =
  ({ email, name, adminId, sortField, sortOrder, currentPage, limit }) =>
  async (dispatch) => {
    try {
      const response = await api.get(`api/admin/search`, {
        params: {
          email,
          name,
          adminId,
          sortField,
          sortOrder,
          currentPage,
          limit,
        },
      });
      dispatch({ type: "SEARCH_ADMINS_SUCCESS", payload: response.data });
    } catch (error) {
      dispatch({ type: "SEARCH_ADMINS_FAILURE", payload: error.response.data });
      if (error.response.status === 401) {
        dispatch({ type: LOGOUT_SUCCESS });
      }
    }
  };
