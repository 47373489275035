import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  register,
  resetPassword,
  toggleLoading,
} from "../redux/actions/authActions";
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Link,
  Avatar,
  FormControl,
  InputRightElement,
  Text,
  Spinner,
} from "@chakra-ui/react";
import { FaLock, FaHandPeace } from "react-icons/fa";
import { useFormik } from "formik";
import { SignupSchema } from "../validationSchemas/SignupSchema";
import { useToast } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import { diffMinutes, diffSeconds } from "helpers";
import { ResetPasswordSchema } from "validationSchemas/ResetPasswordSchema";

const CFaLock = chakra(FaLock);

const ForgotPassword = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [token, setToken] = useState("");
  const [userId, setUserId] = useState("");
  const [myParams, setMyParams] = useSearchParams();
  const handleShowClick = () => setShowPassword(!showPassword);
  const handleShowConfirmClick = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const toast = useToast();
  const dispatch = useDispatch();

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    onSubmit: (values) => {
      dispatch(
        resetPassword({
          password: values.password,
          token: token,
          userId: userId,
        })
      );
    },
    validationSchema: ResetPasswordSchema,
  });

  useEffect(() => {
    const { resetPassword, isError, error } = props;

    const token = myParams.get("token");
    const userId = myParams.get("userId");

    if (token) {
      myParams.delete("token");
    }
    if (userId) {
      myParams.delete("userId");
    }

    if (token) setMyParams(myParams);

    setToken(token);
    setUserId(userId);

    if (resetPassword?.value && diffSeconds(resetPassword?.date) < 3) {
      toast({
        position: "top",
        title: "Account created.",
        description: "Congratulations! Your password has been reset.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else if (!resetPassword?.value && isError) {
      toast({
        position: "top",
        title: "Failure",
        description: error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [props.resetPassword, props.isError, props.error]);

  return (
    <div style={{ color: "white" }}>
      <Flex
        flexDirection="column"
        width="100wh"
        height="100vh"
        backgroundColor="white"
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          flexDir="column"
          mb="2"
          justifyContent="center"
          alignItems="center"
        >
          <FaHandPeace color="darkorange" bg="yellow" size={40} />
          <Heading color="yellow.400">Welcome</Heading>
          <Box minW={{ base: "90%", md: "468px" }}>
            <form onSubmit={formik.handleSubmit}>
              <Stack
                spacing={4}
                p="1rem"
                backgroundColor="white"
                boxShadow="md"
              >
                <FormControl>
                  <InputGroup mb={2}>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<CFaLock color="gray.500" />}
                    />
                    <Input
                      name="password"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      color="black"
                      _placeholder={{ color: "gray.500" }}
                    />

                    <InputRightElement width="4.5rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        background={"none"}
                        _hover={{ color: "yellow.500" }}
                        color={"yellow.400"}
                        _active={{ color: "yellow.700" }}
                        onClick={handleShowClick}
                      >
                        {showPassword ? "Hide" : "Show"}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <div>
                    {formik.errors.password ? (
                      <p style={{ color: "red" }}>{formik.errors.password}</p>
                    ) : null}
                  </div>
                </FormControl>
                <FormControl>
                  <InputGroup mb={2}>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<CFaLock color="gray.500" />}
                    />
                    <Input
                      name="confirmPassword"
                      onChange={formik.handleChange}
                      value={formik.values.confirmPassword}
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Confirm Password"
                      color="black"
                      _placeholder={{ color: "gray.500" }}
                    />

                    <InputRightElement width="4.5rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        background={"none"}
                        _hover={{ color: "yellow.500" }}
                        color={"yellow.400"}
                        _active={{ color: "yellow.700" }}
                        onClick={handleShowConfirmClick}
                      >
                        {showPassword ? "Hide" : "Show"}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <div>
                    {formik.errors.confirmPassword ? (
                      <p style={{ color: "red" }}>
                        {formik.errors.confirmPassword}
                      </p>
                    ) : null}
                  </div>

                  <Button
                    borderRadius={0}
                    type="submit"
                    variant="solid"
                    colorScheme="yellow"
                    width="full"
                  >
                    {/* {!loading && <Spinner mr={2} />} */}
                    Reset Password
                  </Button>
                </FormControl>
                <Flex>
                  <Link color="darkorange" href="/login">
                    Go Back to Login
                  </Link>
                </Flex>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Flex>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    emailSent: state.auth.emailSent,
    isAuthenticated: state.auth.isAuthenticated,
    error: state.auth.error,
    resetPassword: state.auth.resetPassword,
  };
}
export default connect(mapStateToProps)(ForgotPassword);
