import api from "../../utils";
import {
  TOGGLE_LOADING,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  RESEND_EMAIL_SUCCESS,
  RESEND_EMAIL_FAIL,
  SENT_RESET_PASSWORD_SUCCESS,
  SENT_RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_EMAIL_SENT,
} from "../../constants/project";

// Action creators
export const toggleLoading = (loading) => ({
  type: TOGGLE_LOADING,
  payload: loading,
});

export const register = (userData) => async (dispatch) => {
  dispatch(toggleLoading(true));
  try {
    const res = await api.post("/api/auth/register", userData);
    if (res) dispatch({ type: REGISTER_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({ type: REGISTER_FAIL, payload: error.response.data });
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const login = (userData) => async (dispatch) => {
  dispatch(toggleLoading(true));
  try {
    const res = await api.post("/api/auth/login", userData);
    if (res) {
      dispatch({ type: LOGIN_SUCCESS, payload: res.data });
    }
  } catch (error) {
    dispatch({ type: LOGIN_FAIL, payload: error.response.data });
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const sendResetPasswordLink = (userData) => async (dispatch) => {
  dispatch(toggleLoading(true));
  try {
    const res = await api.post("/api/auth/forgotpassword", userData);
    if (res) {
      dispatch({ type: SENT_RESET_PASSWORD_SUCCESS, payload: res.data });
    }
  } catch (error) {
    dispatch({
      type: SENT_RESET_PASSWORD_FAIL,
      payload: error.response.data,
    });
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const resetPassword = (userData) => async (dispatch) => {
  dispatch(toggleLoading(true));
  try {
    const res = await api.post("/api/auth/resetpassword", userData);
    if (res) {
      dispatch({ type: RESET_PASSWORD_SUCCESS, payload: res.data });
    }
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload: error.response.data,
    });
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const getUserById = (userData) => async (dispatch) => {
  dispatch(toggleLoading(true));
  try {
    const res = await api.get("/api/auth/getUserById", {
      params: {
        userId: userData.userId,
        token: userData.token,
      },
    });

    console.log(res.data);
    if (res) {
      dispatch({ type: LOGIN_SUCCESS, payload: res.data });
    }
  } catch (error) {
    dispatch({ type: LOGIN_FAIL, payload: error.response.data });
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const logout = () => async (dispatch) => {
  dispatch(toggleLoading(true));
  try {
    dispatch({ type: LOGOUT_SUCCESS });
  } catch (error) {
    dispatch({ type: LOGOUT_FAIL });
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const resendEmail = (email) => async (dispatch) => {
  dispatch(toggleLoading(true));
  try {
    const res = await api.put("/api/auth/resend", email);
    if (res) dispatch({ type: RESEND_EMAIL_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({ type: RESEND_EMAIL_FAIL, payload: error.response.data });
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const resetEmailSent = () => {
  return { type: RESET_EMAIL_SENT };
};
