export const diffMinutes = (date2) => {
  const d1 = new Date().getTime();
  const d2 = new Date(date2).getTime();

  return Math.round((d1 - d2) / 60000); // Can use Math.floor or Math.ceil depends up to you
};

export const diffSeconds = (date2) => {
  const d1 = new Date().getTime();
  const d2 = new Date(date2).getTime();

  return Math.round((d1 - d2) / 1000); // Can use Math.floor or Math.ceil depends up to you
};
